/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/modalStyle.js";
import Alert from "components/Alert/Alert";

const useStyles = makeStyles(styles);

export default function ValidationForms({ modal, closeModal, color = "Info", tipo, nombre, Form, dataTable = [] }) {
    const classes = useStyles();
    return (
        <div>

            <Dialog
                classes={{
                    root: classes.center + " " + classes.modalRoot,
                    paper: classes.modal + " " + classes.modalLarge,
                }}
                style={{ height: "95vh"}}
                open={modal}
                onClose={() => closeModal()}
                aria-hidden="true"
            >
                <Alert />
                <Card>
                    <CardHeader color="success" text>
                        <GridContainer style={{ marginLeft: `20px`, marginTop: `-35px` }} justify="space-between">
                            <CardText color={color.toLowerCase()}>
                                <h4 className={classes.cardTitle}>{nombre}</h4>
                            </CardText>
                        </GridContainer>
                    </CardHeader>
                    <Form
                        color={color}
                        tipo={tipo}
                        closeModal={closeModal}
                        dataTable={dataTable}
                    />
                </Card>
            </Dialog>
        </div>
    );
}
