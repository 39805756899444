import Dashboard from "views/Dashboard/Dashboard.js";
import LoginPage from "views/Pages/LoginPage.js";
import Productos from "views/Productos";
import Usuarios from "views/Usuarios";
import Ventas from "views/Ventas";
import Venta from "views/Venta";
import Proveedores from "views/Proveedores";
import Compras from "views/Compras";
import Compra from "views/Compra";

// @material-ui/icons
import PermContactCalendarIcon from "@material-ui/icons/PermContactCalendar";
import CategoryIcon from "@material-ui/icons/Category";
import DashboardIcon from "@material-ui/icons/Dashboard";
import GroupIcon from "@material-ui/icons/Group";
import StorefrontIcon from "@material-ui/icons/Storefront";
import Image from "@material-ui/icons/Image";
import LocalGroceryStoreIcon from "@material-ui/icons/LocalGroceryStoreOutlined";
import ReceiptTwoTone from "@material-ui/icons/ReceiptTwoTone";
import SubdirectoryArrowRight from "@material-ui/icons/SubdirectoryArrowRight";
import SubdirectoryArrowLeft from "@material-ui/icons/SubdirectoryArrowLeft";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/admin",
    tipo: ["administrador"],
  },
  {
    collapse: true,
    name: "Pages",
    icon: Image,
    state: "pageCollapse",
    views: [
      {
        path: "/login-page",
        name: "Pagina de inicio",
        mini: "L",
        component: LoginPage,
        layout: "/auth",
      }
    ],
    tipo: [""],
  },
  {
    path: "/productos",
    name: "Productos",
    icon: CategoryIcon,
    component: Productos,
    layout: "/admin",
    tipo: ["administrador"],
  },
  {
    path: "/usuarios",
    name: "Usuarios",
    icon: GroupIcon,
    component: Usuarios,
    layout: "/admin",
    tipo: ["administrador"],
  },
  {
    path: "/proveedores",
    name: "Proveedores",
    icon: PermContactCalendarIcon,
    component: Proveedores,
    layout: "/admin",
    tipo: ["administrador", "vendedor"],
  },
  {
    collapse: true,
    name: "Compras",
    icon: LocalGroceryStoreIcon,
    state: "ComprasCollapse",
    tipo: ["administrador"],
    views: [
      {
        path: "/compras",
        name: "Listado de compras",
        icon: ReceiptTwoTone,
        component: Compras,
        layout: "/admin",
        tipo: ["administrador"],
      },
      {
        path: "/compra",
        name: "Realizar compra",
        icon: SubdirectoryArrowLeft,
        component: Compra,
        layout: "/admin",
        tipo: ["administrador"],
      },
    ],
  },
  {
    collapse: true,
    name: "Ventas",
    icon: StorefrontIcon,
    state: "VentasCollapse",
    tipo: ["administrador", "vendedor"],
    views: [
      {
        path: "/ventas",
        name: "Listado de ventas",
        icon: ReceiptTwoTone,
        component: Ventas,
        layout: "/admin",
        tipo: ["administrador", "vendedor"],
      },
      {
        path: "/venta",
        name: "Realizar venta",
        icon: SubdirectoryArrowRight,
        component: Venta,
        layout: "/admin",
        tipo: ["administrador", "vendedor"],
      },
    ],
  },
];
export default dashRoutes;
