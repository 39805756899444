import axios from "axios";
import jwt from "jwt-decode"
import { setErrorAlert } from "./alertDuck";

const dataInicial = {
  usuario: "",
  nombre: "",
  tipo: "",
};

const LOGIN = "LOGIN";
const LOGOUT = "LOGOUT";

export default function sessionReducer(state = dataInicial, action) {
  switch (action.type) {
  case LOGIN:
    return { ...state, ...action.payload }
  case LOGOUT:
    return { ...dataInicial }
  default:
    return state;
  }
}

export const logIn = (data, setCookie, navigate) => async (dispatch) => {
  try {
    const res = await axios.post("/autenticacion/", data);
    setCookie("session", JSON.stringify(res.data.jwt), { path: "/" });
    axios.defaults.headers.common["Authorization"] = res.data.jwt;
    navigate();
    dispatch({
      type: LOGIN,
      payload: jwt(res.data.jwt),
    });
  } catch (error) {
    dispatch(setErrorAlert(error));
  }
};

export const logOut = (removeCookie, navigate) => async (dispatch) => {
  try {
    removeCookie("session", { path: "/" });
    axios.defaults.headers.common["Authorization"] = "";
    dispatch({
      type: LOGOUT,
    });
    setTimeout(
      () =>
        navigate("/auth/login-page", { replace: true }),
      500
    );
  } catch (error) {
    dispatch(setErrorAlert(error));
  }
};

export const validateSession = (cookies, navigate) => async (dispatch) => {
  try {
    if (!cookies?.session) {
      axios.defaults.headers.common["Authorization"] = "";

      setTimeout(()=>{
        navigate("/auth/login-page", { replace: true })
      }, 1000);
    }
    else {
      axios.defaults.headers.common["Authorization"] = cookies.session;
      navigate("/admin/venta");
      dispatch({
        type: LOGIN,
        payload: jwt(cookies.session)
      });
    }
  } catch (error) {
    dispatch(setErrorAlert(error));
  }
};