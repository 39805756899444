/*eslint-disable*/
import React, { useRef } from "react";

// @material-ui/icons
import Cached from "@material-ui/icons/Cached";
// core components
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import SimpleReactTable from "components/ComponentesModify/SimpleReactTable.js";
import ComprasForm from "components/ComprasForm/ComprasForm";

// redux
import { useDispatch, useSelector } from "react-redux";
import { obtenerProductosCP } from "../../redux/productoDucks";
import { cancelarCompra } from "../../redux/compraDucks.js";
import { cancelarVenta } from "../../redux/ventaDucks.js";

// utils
import { getDate } from "../../utils/utils.js"

// styles
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

const useStyles = makeStyles(styles);

export default function ItemSearch({
  type,
  addItem,
  Icon,
  color = "Info",
  header,
  updateItems,
}) {
  const dispatch = useDispatch();
  const focusTextfielt = useRef();
  const productos = useSelector((store) => store.productos.productos);

  const classes = useStyles();
  const [activateAdd, setActivateAdd] = React.useState(false);
  const initialData = {
    cantidad: "1",
    precioCosto: "",
    precioVenta: "",
    fechaVencimiento: ""
  }
  const [selected, setSelected] = React.useState(initialData);

  const cancelar = () => {
    dispatch(updateItems([]));
    if (type === 1) {
      dispatch(cancelarCompra())
    } else {
      dispatch(cancelarVenta())
    }
  }
  const selectProducto = (data) => {
    const dataTemp = { ...data }
    dataTemp.fechaVencimiento = getDate(dataTemp.fechaVencimiento);
    focusTextfielt.current.focus();
    setSelected(dataTemp);
    setActivateAdd(true);
  };

  const addProduct = (data) => {
    const total = type === 1 ? data.cantidad * data.precioCosto : data.cantidad * data.precioVenta
    const newItem = {
      id: selected._id,
      nombre: selected.nombre,
      precioCosto: data.precioCosto,
      precioVenta: data.precioVenta,
      fechaVencimiento: data.fechaVencimiento,
      cantidad: data.cantidad,
      total: (Math.round(total * 100) / 100).toFixed(2),
    };
    dispatch(addItem(newItem));
    setSelected(initialData);
    setActivateAdd(false);
  };

  React.useEffect(() => {
    dispatch(obtenerProductosCP());
  }, [dispatch]);

  return (
    <Card style={{ height: "80vh", position: "sticky" }}>
      <CardHeader icon>
        <GridContainer justify="space-between">
          <GridItem style={{ marginLeft: `18px` }}>
            <CardIcon
              onClick={() => window.location.reload(false)}
              color={color.toLowerCase()}
            >
              <Icon className={classes.icon} />
            </CardIcon>
          </GridItem>
          <GridItem>
            <CardIcon onClick={() => { cancelar(); }} color="success">
              <Cached className={classes.icon} />
            </CardIcon>
          </GridItem>
        </GridContainer>
      </CardHeader>
      <CardBody>
        <ComprasForm
          color={color}
          addProduct={addProduct}
          selected={selected}
          activateAdd={activateAdd}
          type={type}
          focusTextfielt={focusTextfielt}
        />
        <SimpleReactTable
          columns={header}
          data={productos}
          selectProducto={selectProducto}
          color={color}
        />
      </CardBody>
    </Card>
  );
}
