/*eslint-disable*/
import React from "react";

// @material-ui/icons
import GroupIcon from "@material-ui/icons/Group";
import Add from "@material-ui/icons/Add";

// core components
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import ReactTable from "components/ComponentesModify/ReactTable.js";
import Modal from "components/Modal/Modal.js";
import ModalUsuariosForm from "components/ModalForm/ModalUsuariosForm";
import ActionRows from "components/ActionsRow/ActionRows.js";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
  obtenerUsuarios,
  eliminarUsuario,
  limpiarUsuario,
  obtenerUsuario,
} from "../redux/usuarioDucks";
import { setAlert } from "../redux/alertConfirm";

// style
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

const useStyles = makeStyles(styles);

export default function Usuarios() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const usuarios = useSelector((store) => store.usuarios.usuarios);
  const [modal, setModal] = React.useState(false);
  const [tipoModal, setTipoModal] = React.useState({
    tipo: true,
    color: "Success",
    nombre: "Nuevo Proveedor",
  });

  const activateModal = (tipo, id) => {
    const tipoModal = tipo === "Success";
    if (!tipoModal) {
      dispatch(obtenerUsuario(id));
    }
    setTipoModal({
      tipo: tipoModal,
      color: tipo,
      nombre: tipoModal ? "Nuevo Usuario" : "Editar",
    });
    setModal(true);
  };

  const actions = ActionRows({
    color: "Orange",
    func: (id) => dispatch(setAlert(dataAlert(id))),
    activateModal: activateModal,
    classes: classes,
  });

  React.useEffect(() => {
      dispatch(obtenerUsuarios(actions));
  }, [dispatch]);

  const actionsFunction = (id) => {
    return () => {
      dispatch(eliminarUsuario(id));
      dispatch(obtenerUsuarios(actions));
    };
  };

  const dataAlert = (id) => ({
    func: actionsFunction(id),
    title: "¿Desea eliminar el usuario?",
    info: "El usuario se eliminara de la base de datos",
  });


  const closeModal = () => {
    dispatch(obtenerUsuarios(actions));
    dispatch(limpiarUsuario());
    setModal(false);
  };

  return (
    <>
      <GridContainer>
        <GridItem xs={12}>
          <Card style={{ height: "80vh", position: "sticky"}}>
            <CardHeader icon>
              <GridContainer justify="space-between">
                <GridItem style={{ marginLeft: `18px` }}>
                  <CardIcon
                    onClick={() => window.location.reload(false)}
                    color="orange"
                  >
                    <GroupIcon className={classes.icon} />
                  </CardIcon>
                </GridItem>
                <GridItem>
                  <CardIcon
                    onClick={() => {
                      activateModal("Success");
                    }}
                    color="success"
                  >
                    <Add className={classes.icon} />
                  </CardIcon>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <ReactTable
                columns={[
                  {
                    Header: "Nombre",
                    accessor: "nombre",
                  },
                  {
                    Header: "Usuario",
                    accessor: "usuario",
                  },
                  {
                    Header: "Tipo",
                    accessor: "tipo",
                  },
                  {
                    Header: "Acciones",
                    accessor: "actions",
                  },
                ]}
                data={usuarios}
                color={"Orange"}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <Modal
        Form={ModalUsuariosForm}
        modal={modal}
        closeModal={closeModal}
        tipo={tipoModal.tipo}
        color={tipoModal.color}
        nombre={tipoModal.nombre}
      />
    </>
  );
}
