/*eslint-disable*/
import React from "react";

// @material-ui/icons
import StorefrontIcon from "@material-ui/icons/StorefrontOutlined";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ItemSearch from "components/CompraVenta/ItemSearch.js";
import ItemsConfirm from "components/CompraVenta/ItemsConfirm.js";

// redux
import { useSelector } from "react-redux";
import { updateItems, vender, addItem } from "../redux/ventaDucks.js";

export default function Ventas() {
  const items = useSelector((store) => store.ventas.items);
  const header = [
    {
      Header: "Nombre",
      accessor: "nombre",
    },
    {
      Header: "Precio Venta",
      accessor: "precioVenta",
    },
    {
      Header: "Existencia",
      accessor: "existencia",
    },
    {
      Header: "Fecha Vencimiento",
      accessor: "fechaVencimiento",
    },
    {
      Header: "Descripcion",
      accessor: "descripcion",
    },
  ];
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} md={7}>
          <ItemSearch
            Icon={StorefrontIcon}
            type={2}
            addItem={addItem}
            updateItems={updateItems}
            color={"Warning"}
            header={header}
          />
        </GridItem>
        <GridItem xs={12} md={5}>
            <ItemsConfirm
              Icon={StorefrontIcon}
              type={2}
              updateItems={updateItems}
              compraVenta={vender}
              items={items}
              color={"Warning"}
            />
        </GridItem>
      </GridContainer>
    </div>
  );
}
