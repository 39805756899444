import axios from "axios";
import { setAlert, setErrorAlert } from "./alertDuck";

const newProveedor = {
  nombre: "",
  correo: "",
  celular: "",
  empresa: "",
  descripcion: "",
};

const dataInicial = {
  proveedor: newProveedor,
  proveedores: [],
  proveedoresUsuarioSimple: [],
  proveedoresUsuario: [],
};

const OBTENER_PROVEEDORES = "OBTENER_PROVEEDORES";
const OBTENER_PROVEEDORES_USUARIO = "OBTENER_PROVEEDORES_USUARIO";
const OBTENER_PROVEEDOR = "OBTENER_PROVEEDOR";
const NUEVO_PROVEEDOR = "NUEVO_PROVEEDOR";
const EDIT_PROVEEDOR = "EDIT_PROVEEDOR";
const LIMPIAR_PROVEEDOR = "LIMPIAR_PROVEEDOR";

export default function proveedorReducer(state = dataInicial, action) {
  switch (action.type) {
  case OBTENER_PROVEEDORES_USUARIO:
    return { ...state, proveedoresUsuario: action.payload };
  case OBTENER_PROVEEDORES:
    return { ...state, proveedores: action.payload };
  case OBTENER_PROVEEDOR:
  case NUEVO_PROVEEDOR:
  case EDIT_PROVEEDOR:
  case LIMPIAR_PROVEEDOR:
    return { ...state, proveedor: action.payload };
  default:
    return state;
  }
}

export const editarProveedor = (producto, id) => async (dispatch) => {
  try {
    const editProducto = {
      nombre: producto.nombre,
      celular: producto.celular,
      empresa: producto.empresa,
      correo: producto.correo,
      descripcion: producto.descripcion,
    };
    await axios.put("/proveedor/" + id, editProducto);
    dispatch(
      setAlert({ tipo: "success", msj: "Proveedor modificado con exito" })
    );
  } catch (error) {
    dispatch(setErrorAlert(error));
  }
};

export const nuevoProveedor = (proveedor, closeModal) => async (dispatch) => {
  try {
    await axios.post("/proveedor", proveedor);
    dispatch(
      setAlert({ tipo: "success", msj: "El proveedor se agrego con exito" })
    );
    closeModal();
  } catch (error) {
    dispatch(setErrorAlert(error));
  }
};

export const obtenerProveedores = (actions) => async (dispatch) => {
  try {
    let res = await axios.get("/proveedor/all?limit=0&skip=0");
    res.data = res.data.map((item) => ({
      ...item,
      actions: actions(item._id),
    }));
    dispatch({
      type: OBTENER_PROVEEDORES,
      payload: res.data,
    });
  } catch (error) {
    dispatch(setErrorAlert(error));
  }
};

export const obtenerProveedoresUsuario = () => async (dispatch) => {
  try {
    let res = await axios.get("/proveedor/all?limit=0&skip=0");
    res.data = res.data.map((item) => ({
      ...item,
      value: item._id,
      label: item.nombre + " - " + item.empresa,
    }));
    dispatch({
      type: OBTENER_PROVEEDORES_USUARIO,
      payload: res.data,
    });
  } catch (error) {
    dispatch(setErrorAlert(error));
  }
};

export const obtenerProveedor = (id) => async (dispatch) => {
  try {
    let res = await axios.get("/proveedor/" + id);

    dispatch({
      type: OBTENER_PROVEEDOR,
      payload: res.data,
    });
  } catch (error) {
    dispatch(setErrorAlert(error));
  }
};

export const eliminarProveedor = (id) => async (dispatch) => {
  try {
    await axios.delete("/proveedor/" + id);
    dispatch(
      setAlert({ tipo: "success", msj: "Proveedor eliminado con exito" })
    );
  } catch (error) {
    dispatch(setErrorAlert(error));
  }
};

export const limpiarProveedor = () => async (dispatch) => {
  try {
    dispatch({
      type: LIMPIAR_PROVEEDOR,
      payload: newProveedor,
    });
  } catch (error) {
    dispatch(setErrorAlert(error));
  }
};
