import axios from "axios";
import { setAlert, setErrorAlert } from "./alertDuck";

const newItem = {
  nombre: "",
  codigo: "",
  precio: "",
  cantidad: "",
  fechaVencimiento: "",
};

const dataInicial = {
  intemCompra: newItem,
  items: [],
  compra: {},
  compras: [],
};

const UPDATE_ITEMS_COMPRA = "UPDATE_ITEMS_COMPRA";
const AGREGAR_ITEM_COMPRA = "AGREGAR_ITEM_COMPRA";
const AGREGAR_COMPRA = "AGREGAR_COMPRA";
const OBTENER_COMPRAS = "OBTENER_COMPRAS";

export default function comprasReducer(state = dataInicial, action) {
  switch (action.type) {
  case AGREGAR_ITEM_COMPRA:
    return existItem(state, action.payload);
  case UPDATE_ITEMS_COMPRA:
    return { ...state, items: action.payload };
  case AGREGAR_COMPRA:
    return { ...state, compra: action.payload };
  case OBTENER_COMPRAS:
    return { ...state, compras: action.payload };
  default:
    return state;
  }
}

const existItem = (state, newItem) => {
  const some = state.items.some((item) => {
    return item.nombre === newItem.nombre;
  });
  if (!some) {
    return { ...state, items: [...state.items, newItem] };
  }
  throw {
    tipo: "warning",
    msj: "El producto \"" + newItem.nombre + "\" ya se agrego a la lista",
  };
};

export const confirmarCompra = (compra, fecha, closeModal) => async (
  dispatch
) => {
  try {
    compra.fecha = fecha;
    await axios.post("/compra", compra);
    closeModal(true);
    dispatch(setAlert({ tipo: "success", msj: "Compra realizada con exito" }));
    dispatch({
      type: UPDATE_ITEMS_COMPRA,
      payload: [],
    });
  } catch (error) {
    dispatch(setErrorAlert(error));
  }
};

export const cancelarCompra = () => async (dispatch) => {
  try {
    dispatch({
      type: AGREGAR_COMPRA,
      payload: {},
    });
  } catch (error) {
    dispatch(setErrorAlert(error));
  }
};

export const comprar = (compra) => async (dispatch) => {
  try {
    dispatch({
      type: AGREGAR_COMPRA,
      payload: compra,
    });
  } catch (error) {
    dispatch(setErrorAlert(error));
  }
};

export const addItem = (item) => (dispatch) => {
  try {
    dispatch({
      type: AGREGAR_ITEM_COMPRA,
      payload: item,
    });
  } catch (error) {
    dispatch(setAlert(error));
  }
};

export const updateItems = (items) => (dispatch) => {
  try {
    dispatch({
      type: UPDATE_ITEMS_COMPRA,
      payload: items,
    });
  } catch (error) {
    dispatch(setErrorAlert(error));
  }
};
const getMoneyFormat = (amount) => {
  return amount
    .toLocaleString("es-MX", {
      style: "currency",
      currency: "USD",
    })
    .replace("USD", "Q");
};

const getDate = (dateString) => {
  const date = new Date(dateString);
  const stringDate =
    date.toLocaleDateString("en-GB") + " " + date.toLocaleTimeString("en-GB");

  return stringDate;
};

export const obtenerCompras = (filter = "") => async (dispatch) => {
  try {
    let res = await axios.get("/compra/all?limit=100&skip=0&date=" + filter);
    res.data = res.data.map((item) => {
      return {
        color: "success",
        data: [
          (item.nit = "NF"),
          (item.nombre = "Agro"),
          getMoneyFormat(item.total),
          getDate(item.fecha),
        ],
        detail: item,
      };
    });
    dispatch({
      type: OBTENER_COMPRAS,
      payload: res.data,
    });
  } catch (error) {
    dispatch(setErrorAlert(error));
  }
};
