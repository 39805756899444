/*eslint-disable*/
import axios from "axios";
import { setAlert, setErrorAlert } from "./alertDuck";

const newItem = {
  nombre: "",
  codigo: "",
  precio: "",
  cantidad: "",
};
const data = JSON.parse(localStorage.getItem("listaProductosVenta"));
console.log(data);
const dataInicial = {
  intemCompra: newItem,
  items: JSON.parse(localStorage.getItem("listaProductosVenta")) || [],
  venta: {},
  ventas: [],
};

const AGREGAR_ITEM_VENTA = "AGREGAR_ITEM_VENTA";
const UPDATE_ITEMS_VENTA = "UPDATE_ITEMS_VENTA";
const AGREGAR_VENTA = "AGREGAR_VENTA";
const OBTENER_VENTAS = "OBTENER_VENTAS";

export default function ventasReducer(state = dataInicial, action) {
  switch (action.type) {
    case AGREGAR_ITEM_VENTA:
      return existItem(state, action.payload);
    case UPDATE_ITEMS_VENTA:
      const productos = { ...state, items: action.payload };
      localStorage.setItem(
        "listaProductosVenta",
        JSON.stringify(productos.items)
      );
      return productos;
    case AGREGAR_VENTA:
      return { ...state, venta: action.payload };
    case OBTENER_VENTAS:
      return { ...state, ventas: action.payload };
    default:
      return state;
  }
}

const existItem = (state, newItem) => {
  const some = state.items.some((item) => item.nombre === newItem.nombre);
  if (!some) {
    return { ...state, items: [...state.items, newItem] };
  } else {
    const items = state.items.map((item) => {
      if (item.nombre === newItem.nombre) {
        item.cantidad = parseInt(item.cantidad) + parseInt(newItem.cantidad);
      }
      return item;
    });
    return { ...state, items: [...items] };
  }
};

export const vender = (venta) => async (dispatch) => {
  try {
    dispatch({
      type: AGREGAR_VENTA,
      payload: venta,
    });
  } catch (error) {
    dispatch(setErrorAlert(error));
  }
};

export const cancelarVenta = () => async (dispatch) => {
  try {
    dispatch({
      type: AGREGAR_VENTA,
      payload: {},
    });
  } catch (error) {
    dispatch(setErrorAlert(error));
  }
};

export const confirmarVenta = (
  venta,
  fecha,
  closeModal,
  actualizarProductos
) => async (dispatch) => {
  try {
    venta.nit = "NF";
    venta.nombre = "Consumidor final";
    venta.fecha = fecha;
    await axios.post("/venta/sinFactura", venta);
    actualizarProductos();
    closeModal(true);
    dispatch(setAlert({ tipo: "success", msj: "Venta realizada con exito" }));
    dispatch({
      type: UPDATE_ITEMS_VENTA,
      payload: [],
    });
    localStorage.setItem("listaProductosVenta", "[]");
  } catch (error) {
    dispatch(setErrorAlert(error));
  }
};

export const confirmarVentaNit = (
  venta,
  nit,
  nombre,
  fecha,
  direccion,
  closeModal,
  actualizarProductos
) => async (dispatch) => {
  try {
    venta.nit = nit;
    venta.direccion = direccion;
    venta.nombre = nombre;
    venta.fecha = fecha;

    if (nit.toLowerCase() !== "cf") {
      //await axios.get("/certificador/retornarDatosCliente/" + nit);
    }

    const res = await axios.post("/venta/conFactura", venta);
    actualizarProductos();

    try {
      await downloadPDF(res.data.uuid, nombre);
    } catch (err) {
      closeModal(true);
      dispatch(
        setErrorAlert({
          message: "Se realizo la venta pero hubo error generando el pdf",
        })
      );
      setTimeout(() => {}, 5000);
    }
    dispatch(setAlert({ tipo: "success", msj: "Venta realizada con exito" }));
    dispatch({
      type: UPDATE_ITEMS_VENTA,
      payload: [],
    });
    localStorage.setItem("listaProductosVenta", "[]");
    closeModal(true);
  } catch (error) {
    closeModal(true);
    dispatch(setErrorAlert(error));
  }
};

export const facturarSinVenta = (
  venta,
  nit,
  nombre,
  fecha,
  direccion,
  closeModal
) => async (dispatch) => {
  try {
    venta.nit = nit;
    venta.direccion = direccion;
    venta.nombre = nombre;
    venta.fecha = fecha;
    const res = await axios.post("/venta/createFactura", venta);
    try {
      await downloadPDF(res.data.uuid, "Factura sin venta - " + nombre);
    } catch (err) {
      closeModal(true);
      dispatch(
        setErrorAlert({
          message: "Se realizo la factura pero hubo error generando el pdf",
        })
      );
      setTimeout(() => {}, 5000);
    }
    dispatch(setAlert({ tipo: "success", msj: "Factura realizada con exito" }));
    dispatch({
      type: UPDATE_ITEMS_VENTA,
      payload: [],
    });
    closeModal(true);
    localStorage.setItem("listaProductosVenta", "[]");
  } catch (error) {
    closeModal(true);
    dispatch(setErrorAlert(error));
  }
};

const formatDate = (date) => {
  return date.split(".")[0] + ".000-06:00";
};

export const anularFactura = (data, actualizarVentas) => async (dispatch) => {
  try {
    data.fechaEmicion = formatDate(data.fechaEmicion);
    await axios.post("/certificador/anularDocumentoXML", data);
    actualizarVentas();
    dispatch(
      setAlert({ tipo: "success", msj: "Anulacion realizada con exito" })
    );
  } catch (error) {
    dispatch(setErrorAlert(error));
  }
};

export const anularVenta = (data, actualizarVentas) => async (dispatch) => {
  try {
    data.fechaEmicion = formatDate(data.fechaEmicion);
    await axios.post("/venta/anularVenta", data);
    actualizarVentas();
    dispatch(
      setAlert({ tipo: "success", msj: "Anulacion realizada con exito" })
    );
  } catch (error) {
    dispatch(setErrorAlert(error));
  }
};

export const addItem = (item) => (dispatch) => {
  try {
    item.fechaVencimiento = null;
    dispatch({
      type: AGREGAR_ITEM_VENTA,
      payload: item,
    });
  } catch (error) {
    dispatch(setAlert(error));
  }
};

export const updateItems = (items) => (dispatch) => {
  try {
    dispatch({
      type: UPDATE_ITEMS_VENTA,
      payload: items,
    });
  } catch (error) {
    dispatch(setErrorAlert(error));
  }
};

const getMoneyFormat = (amount) => {
  return amount
    .toLocaleString("es-MX", {
      style: "currency",
      currency: "USD",
    })
    .replace("USD", "Q");
};

const getDate = (dateString) => {
  const auxSplit = dateString.split("T");
  const dateSplit = auxSplit[0].split("-");
  const newDate = `${dateSplit[2]}/${dateSplit[1]}/${dateSplit[0]}`;

  return `${newDate} ${auxSplit[1].split(".")[0]}`;
};

const getRowColor = (data, anulado) => {
  if (anulado ? true : false) {
    return "danger";
  }
  switch (data) {
    case "NF":
      return "warning";
    case "CF":
      return "info";
    default:
      return "success";
  }
};

export const obtenerVentas = (filter = "") => async (dispatch) => {
  try {
    let res = await axios.get("/venta/all?limit=100&skip=0&date=" + filter);
    res.data = res.data.map((item) => {
      return {
        color: getRowColor(item.nit, item.anulado),
        data: [
          item.nit,
          item.nombre,
          getMoneyFormat(item.total),
          getDate(item.fecha),
        ],
        detail: item,
      };
    });
    dispatch({
      type: OBTENER_VENTAS,
      payload: res.data,
    });
  } catch (error) {
    dispatch(setErrorAlert(error));
  }
};

export const obtenerInformacionCliente = (
  nit,
  setNombre,
  setDireccion,
  setError
) => async (dispatch) => {
  try {
    let res = await axios.get("/certificador/retornarDatosCliente/" + nit);
    setNombre(res.data.nombre);
    setDireccion(res.data.direccion);
  } catch (error) {
    if (error?.response?.data?.message === "error solicitando nit") {
      dispatch(setErrorAlert(error));
    } else {
      setError("Nit invalido");
    }
    setNombre("");
    setDireccion("");
  }
};

export const obtenerPDF = (uuid, nombre) => async (dispatch) => {
  try {
    await downloadPDF(uuid, nombre);
  } catch (error) {
    dispatch(setErrorAlert(error));
  }
};

const downloadPDF = async (uuid, nombre) => {
  let res = await axios.post("/certificador/retornarPDF/" + uuid);
  let a = document.createElement("a");
  a.href = "data:application/octet-stream;base64," + res.data.pdf;
  a.download = `${nombre} - ${uuid}.pdf`;
  a.click();
};
