/*eslint-disable*/
import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import MenuItem from '@material-ui/core/MenuItem';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";

// style for this view
import styles1 from "assets/jss/material-dashboard-pro-react/modalStyle.js";
import styles2 from "assets/jss/material-dashboard-pro-react/customSelectStyleInfo.js";
import styles3 from "assets/jss/material-dashboard-pro-react/components/customInputStyle.js";

// Validate imputs
import { useFormik } from 'formik';
import { useDispatch, useSelector } from "react-redux";
import { nuevoUsuario, editarUsuario } from "../../redux/usuarioDucks";
import { usuarioSchema } from "../../utils/schemas";

const useStyles = makeStyles({
    ...styles1, ...styles2, ...styles3
});

export default function ModalUsuariosForm({ tipo, color = "Info", closeModal }) {
    const dispatch = useDispatch();
    const classes = useStyles();

    const usuario = useSelector((store) => store.usuarios.usuario);

    const formik = useFormik({
        initialValues: usuario,
        enableReinitialize: true,
        validationSchema: usuarioSchema,
        onSubmit: (values) => {
            const dataTemp = {
                nombre: values.nombre,
                usuario: values.usuario,
                pwd: values.pwd,
                tipo: values.tipo,
            }
            if (tipo) dispatch(nuevoUsuario(dataTemp, closeModal))
            else dispatch(editarUsuario(dataTemp, usuario._id))
        },
    });
    
    const types = [
        {
            value: 'administrador',
            label: 'administrador',
        },
        {
            value: 'vendedor',
            label: 'vendedor',
        },
        {
            value: 'consultor',
            label: 'consultor',
        }
    ];

    const getForm = () => {
        return (
            <>
                <GridContainer>
                    <GridItem xs={12} sm={6}>
                        {inpuForm("Nombre", "text", "nombre")}
                        {inpuForm("Usuario", "text", "usuario")}
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                        {inpuForm("Tipo", "select", "tipo", types)}
                        {inpuForm("Nueva contraseña", "password", "pwd")}
                    </GridItem>
                </GridContainer >
            </>
        )
    }

    const inpuForm = (name, type, id, types = []) => (
        <GridContainer>
            <GridItem style={{ textAlign: 'right', marginTop: '35px' }} xs={12} sm={3}>
                <FormLabel>
                    {name}
                </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={8}>
                <TextField
                    fullWidth
                    className={classes.formControl}
                    InputProps={{
                        className: classes["input" + color],
                        onChange: formik.handleChange,
                        id: id,
                        name: id,
                        value: formik.values[id] !== null ? formik.values[id] : "",
                    }}
                    value={type === "select" ? formik.values[id] : ""}
                    error={formik.touched[id] && Boolean(formik.errors[id])}
                    helperText={formik.touched[id] && formik.errors[id]}
                    variant="standard"
                    select={type === "select"}
                    type={type}
                >
                    {types.map((item) => (
                        <MenuItem
                            classes={{
                                root: classes["selectMenuItem" + color],
                                selected: classes["selectMenuItemSelected" + color],
                            }} 
                            key={item.value} 
                            value={item.value}
                        >
                            {item.label}
                        </MenuItem>
                    ))}
                </TextField>
            </GridItem>
        </GridContainer>
    )

    return (
        <form onSubmit={formik.handleSubmit}>
            <CardBody>
                {getForm()}
            </CardBody>
            <CardFooter >
                <Button style={{ marginLeft: "auto" }} color={color.toLowerCase()} type="submit">
                    {tipo ? "Crear" : "Guardar"}
                </Button>
                <Button style={{ marginLeft: "10px" }} color="danger" onClick={() => closeModal()}>
                    Cancelar
                </Button>
            </CardFooter>
        </form>
    );
}
