import * as yup from "yup";

const proveedorSchema = yup.object({
  nombre: yup.string("Ingresar nombre").required("El nombre es requerido"),
  correo: yup.string("Ingresar correo electronico").email("Correo incorrecto"),
  celular: yup
    .number("Ingresar el numero de celular")
    .min(0, "Numero de celular incorrecto")
    .required("El numero de celular es requerido")
    .nullable(),
  empresa: yup.string("Nombre de empresa"),
  descripcion: yup.string("Ingresar descripcion"),
});

const productoSchema = yup.object({
  nombre: yup.string("Ingresar nombre").required("El nombre es requerido"),
  codigoBarras: yup.string("Ingresar codigo de barras"),
  precioCosto: yup
    .number("Ingresar precio costo")
    .min(0, "El precio costo debe de ser mayor a 0")
    .required("El precio costo es requerido")
    .nullable(),
  precioVenta: yup
    .number("Ingresar precio venta")
    .min(0, "El precio venta debe de ser mayor a 0")
    .required("El precio venta es requerido"),
  existencia: yup
    .number("Ingresar existencia")
    .min(0, "La existencia debe de ser mayor a 0")
    .required("La existencia es requerida"),
  existenciaMinima: yup
    .number("Ingresar existencia minima")
    .min(0, "La existencia minima debe de ser mayor a 0")
    .required("La existencia minima es requerida"),
  fechaVencimiento: yup.date("Ingresar fecha valida").nullable(),
  descripcion: yup.string("Ingresar descripcion"),
});

const usuarioSchema = yup.object({
  nombre: yup.string("Ingresar nombre").required("El nombre es requerido"),
  usuario: yup.string("Ingresar usuario").required("El usuario es requerido"),
  pwd: yup
    .string("Ingresar contraseña")
    .required("La contraseña es requerida")
    .min(8, "Debe tener como minimo 8 caracteres"),
  tipo: yup.string("Ingresar tipo").required("El tipo es requerido"),
});

const loginSchema = yup.object({
  usuario: yup.string("Ingresar usuario").required("El usuario es requerido"),
  pwd: yup.string("Ingresar contraseña").required("La contraseña es requerida"),
});

const nitSchema = yup.object({
  nit: yup.string("Ingresar nit").required("El nit es requerido"),
});

export {
  productoSchema,
  proveedorSchema,
  usuarioSchema,
  loginSchema,
  nitSchema,
};
