/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from "@material-ui/core/InputAdornment";
import Switch from "@material-ui/core/Switch";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";

// style for this view
import styles1 from "assets/jss/material-dashboard-pro-react/modalStyle.js";
import styles2 from "assets/jss/material-dashboard-pro-react/customSelectStyleInfo.js";
import styles3 from "assets/jss/material-dashboard-pro-react/components/customInputStyle.js";
import styles4 from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";

import { useFormik } from 'formik';
import { useDispatch, useSelector } from "react-redux";
import { nuevoProducto, editarProducto } from "../../redux/productoDucks.js";
import { obtenerProveedoresUsuario } from "../../redux/proveedorDucks";

import { productoSchema } from "../../utils/schemas";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles({
    ...styles1, ...styles2, ...styles3, ...styles4
});

export default function ModalProductosForm({ tipo, color = "Info", closeModal }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const producto = useSelector((store) => store.productos.producto);
    const proveedores = useSelector((store) => store.proveedores.proveedoresUsuario);
    const regex = /"|'|<|>/ig;
    const [permiso, setPermiso] = React.useState(producto.permisoMod);
    React.useEffect(() => {
        dispatch(obtenerProveedoresUsuario());
    }, [dispatch]);
    const formik = useFormik({
        initialValues: producto,
        enableReinitialize: true,
        validationSchema: productoSchema,
        onSubmit: (values) => {
            values.nombre = values.nombre.replaceAll(regex, "")
            values.nombre = values.nombre.replaceAll("&", "y")
            values.codigoBarras = values.codigoBarras.trim()
            const dataTemp = {
                nombre: values.nombre,
                codigoBarras: values.codigoBarras !== "" ? values.codigoBarras : null,
                precioCosto: values.precioCosto,
                precioVenta: values.precioVenta,
                existencia: values.existencia,
                existenciaMinima: values.existenciaMinima,
                fechaVencimiento: values.fechaVencimiento !== "" ? values.fechaVencimiento : null,
                descripcion: values.descripcion,
                permisoMod: values.permisoMod === null ? false : values.permisoMod,
                proveedor: proveedores.find((item) => (item._id === values.provId)),
            }
            if (dataTemp.proveedor) {
                delete dataTemp.proveedor.label;
                delete dataTemp.proveedor.value;
            }
            if (tipo) dispatch(nuevoProducto(dataTemp, closeModal))
            else dispatch(editarProducto(dataTemp, producto._id))
        },
    });
    const getForm = () => {
        return (
            <>
                <GridContainer>
                    <GridItem xs={12} sm={6}>
                        {inpuForm("Nombre", "text", "nombre")}
                        {inpuForm("Codigo de Barras", "text", "codigoBarras")}
                        {inpuForm("Precio Costo", "number", "precioCosto")}
                        {inpuForm("Precio Venta", "number", "precioVenta")}
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                        {inpuForm("Existencia", "number", "existencia")}
                        {inpuForm("Existencia Minima", "number", "existenciaMinima")}
                        {inpuForm("Fecha de Vencimiento", "date", "fechaVencimiento",)}
                        {inpuForm("Proveedor", "select", "provId", proveedores)}
                    </GridItem>
                </GridContainer >
                <GridContainer>
                    <GridItem xs={12} sm={6}>
                        {inpuForm("Descripcion", "text", "descripcion")}
                    </GridItem>
                    <GridItem style={{ marginTop: '35px' }} xs={12} sm={6}>
                        <div style={{ marginLeft: '80px' }} >
                            <label className={classes.label}>{permiso ? "Modificacion de precio bloqueada" : "Modificacion de precio permitida"}</label>
                            <Switch
                                checked={formik.values["permisoMod"] !== null ? formik.values["permisoMod"] : false}
                                onChange={formik.handleChange}
                                id="permisoMod"
                                name="permisoMod"
                                classes={{
                                    switchBase: classes["switch" + color],
                                    checked: classes["switchChecked" + color],
                                    thumb: classes.switchIcon,
                                    track: classes.switchBar,
                                }}
                            />
                        </div>
                    </GridItem>
                </GridContainer >
            </>
        )
    }

    const getMoneyIcon = (type) => {
        if (type === "precioCosto" || type === "precioVenta") {
            return (
                <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                >
                    Q{""}
                </InputAdornment>
            );
        } else {
            return null;
        }
    };

    const inpuForm = (name, type, id, data = []) => {
        var value = formik.values[id] !== null ? formik.values[id] : "";
        return (
            <GridContainer>
                <GridItem style={{ textAlign: 'right', marginTop: '35px' }} xs={12} sm={3}>
                    <FormLabel>
                        {name}
                    </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={8}>
                    <TextField
                        key={"fp" + name}
                        fullWidth
                        className={classes.formControl}
                        InputProps={{
                            className: classes["input" + color],
                            onChange: formik.handleChange,
                            id: id,
                            name: id,
                            value: value,
                            startAdornment: getMoneyIcon(id),
                        }}
                        value={type === "select" ? value : ""}
                        error={formik.touched[id] && Boolean(formik.errors[id])}
                        helperText={formik.touched[id] && formik.errors[id]}
                        variant="standard"
                        select={type === "select"}
                        type={type}
                    >
                        {data.map((item, key) => (
                            <MenuItem
                                classes={{
                                    root: classes["selectMenuItem" + color],
                                    selected: classes["selectMenuItemSelected" + color],
                                }}
                                key={"pl" + key}
                                value={item.value}
                            >
                                {item.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </GridItem>
            </GridContainer>
        )
    };

    return (
        <form onSubmit={formik.handleSubmit}>
            <CardBody>
                {getForm()}
            </CardBody>
            <CardFooter >
                <Button style={{ marginLeft: "auto" }} color={color.toLowerCase()} type="submit">
                    {tipo ? "Crear" : "Guardar"}
                </Button>
                <Button style={{ marginLeft: "10px" }} color="danger" onClick={() => closeModal()}>
                    Cancelar
                </Button>
            </CardFooter>
        </form>
    );
}
