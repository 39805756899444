/*eslint-disable*/
import axios from "axios";
import { setAlert, setErrorAlert } from "./alertDuck";

const newUsuario = {
  usuario: "",
  nombre: "",
  pwd: "",
  tipo: "vendedor",
};

const dataInicial = {
  usuario: newUsuario,
  usuarios: [],
};

const OBTENER_USUARIOS = "OBTENER_USUARIOS";
const NUEVO_USUARIO = "NUEVO_USUARIO";
const EDIT_USUARIO = "EDIT_USUARIO";
const LIMPIAR_USUARIO = "LIMPIAR_USUARIO";
const OBTENER_USUARIO = "OBTENER_USUARIO";
export default function usuarioReducer(state = dataInicial, action) {
  switch (action.type) {
    case OBTENER_USUARIOS:
      return { ...state, usuarios: action.payload };
    case NUEVO_USUARIO:
    case LIMPIAR_USUARIO:
    case OBTENER_USUARIO:
    case EDIT_USUARIO:
      return { ...state, usuario: action.payload };
    default:
      return state;
  }
}

export const editarUsuario = (usuario, id) => async (dispatch) => {
  try {
    const editusuario = {
      nombre: usuario.nombre,
      usuario: usuario.usuario,
      tipo: usuario.tipo,
      pwd: usuario.pwd,
    };
    await axios.put("/usuario/" + id, editusuario);
    dispatch(setAlert({tipo: "success", msj: "Usuario modificado con exito"}));
  } catch (error) {
    dispatch(setErrorAlert(error));
  }
};

export const nuevoUsuario = (usuario, closeModal) => async (dispatch) => {
  try {
    await axios.post("/usuario", usuario);
    dispatch(setAlert({tipo: "success", msj: "Usuario creado con exito"}))
    closeModal();
  } catch (error) {
    dispatch(setErrorAlert(error));
  }
};

export const obtenerUsuarios = (actions) => async (dispatch) => {
  try {
    let res = await axios.get("/usuario/all?limit=0&skip=0");

    res.data = res.data.map((item) => ({
      ...item,
      actions: actions(item._id),
    }));
    dispatch({
      type: OBTENER_USUARIOS,
      payload: res.data,
    });
  } catch (error) {
    dispatch(setErrorAlert(error));
  }
};

export const obtenerUsuario = (id) => async (dispatch) => {
  try {
    let res = await axios.get("/usuario/" + id);
    res.data.pwd = "";
    dispatch({
      type: OBTENER_USUARIO,
      payload: res.data,
    });
  } catch (error) {
    dispatch(setErrorAlert(error));
  }
};

export const eliminarUsuario = (id) => async (dispatch) => {
  try {
    await axios.delete("/usuario/" + id);
    dispatch(setAlert({tipo: "warning", msj: "Usuario eliminado con exito"}))
  } catch (error) {
    dispatch(setErrorAlert(error));
  }
};

export const limpiarUsuario = () => async (dispatch) => {
  try {
    dispatch({
      type: LIMPIAR_USUARIO,
      payload: newUsuario,
    });
  } catch (error) {
    dispatch(setErrorAlert(error));
  }
};
