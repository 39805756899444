/*eslint-disable*/
import axios from "axios";
import { setAlert, setErrorAlert } from "./alertDuck";

const newProducto = {
  nombre: "",
  codigoBarras: "",
  precioCosto: "0.00",
  precioVenta: "0.00",
  existencia: "0",
  existenciaMinima: "0",
  fechaVencimiento: "",
  descripcion: "",
  proveedor: null,
  provId: "",
  permisoMod: false,
};

const dataInicial = {
  producto: newProducto,
  productos: [],
  productosSimples: [],
};

const OBTENER_PRODUCTOS = "OBTENER_PRODUCTOS";
const OBTENER_PRODUCTO = "OBTENER_PRODUCTO";
const NUEVO_PRODUCTO = "PRODUCTO_NUEVO";
const EDIT_PRODUCTO = "PRODUCTO_EDIT";
const LIMPIAR_PRODUCTO = "PRODUCTO_LIMPIAR";

export default function productoReducer(state = dataInicial, action) {
  switch (action.type) {
    case OBTENER_PRODUCTOS:
      return { ...state, productos: action.payload };
    case OBTENER_PRODUCTO:
    case NUEVO_PRODUCTO:
    case EDIT_PRODUCTO:
    case LIMPIAR_PRODUCTO:
      return { ...state, producto: action.payload };
    default:
      return state;
  }
}

export const editarProducto = (producto, id) => async (dispatch) => {
  try {
    if(producto.codigoBarras === null){
      delete producto.codigoBarras
    }
    await axios.put("/productos/" + id, producto);
    dispatch(
      setAlert({ tipo: "success", msj: "El producto se modificado con exito" })
    );
  } catch (error) {
    dispatch(setErrorAlert(error));
  }
};

export const nuevoProducto = (producto, closeModal) => async (dispatch) => {
  try {
    if (producto.codigoBarras === null) {
      delete producto.codigoBarras;
    }
    await axios.post("/productos", producto);
    closeModal();
    dispatch(
      setAlert({ tipo: "success", msj: "El producto agregado con exito" })
    );
  } catch (error) {
    dispatch(setErrorAlert(error));
  }
};

const formatoProveedor = (proveedor) => {
  if (!proveedor) return "";
  const empresa = proveedor.empresa ? " - " + proveedor.empresa : "";
  const celular = proveedor.celular ? " - " + proveedor.celular : "";
  return proveedor.nombre + empresa + celular;
};

export const obtenerProductos = (actions) => async (dispatch) => {
  try {
    let res = await axios.get("/productos/all?limit=0&skip=0");
    res.data = res.data.map((item) => {
      const date = new Date(
        item.fechaVencimiento
          ? item.fechaVencimiento.split("T")[0].replace("-", "/")
          : ""
      );

      return {
        ...item,
        fechaVencimiento: item.fechaVencimiento
          ? date.toLocaleDateString("en-GB")
          : "",
        actions: actions(item._id),
        prov: formatoProveedor(item.proveedor),
      };
    });
    dispatch({
      type: OBTENER_PRODUCTOS,
      payload: res.data,
    });
  } catch (error) {
    dispatch(setErrorAlert(error));
  }
};

export const obtenerProductosCP = () => async (dispatch) => {
  try {
    let res = await axios.get("/productos/all?limit=0&skip=0");
    res.data = res.data.map((item) => {
      const date = new Date(
        item.fechaVencimiento
          ? item.fechaVencimiento.split("T")[0].replace("-", "/")
          : ""
      );
      return {
        ...item,
        fechaVencimiento: item.fechaVencimiento
          ? date.toLocaleDateString("en-GB")
          : "",
        prov: formatoProveedor(item.proveedor),
      };
    });
    dispatch({
      type: OBTENER_PRODUCTOS,
      payload: res.data,
    });
  } catch (error) {
    dispatch(setErrorAlert(error));
  }
};

export const obtenerProducto = (id) => async (dispatch) => {
  try {
    let res = await axios.get("/productos/" + id);
    res.data.provId = res.data.proveedor ? res.data.proveedor._id : "";
    res.data.codigoBarras = res.data.codigoBarras ? res.data.codigoBarras : "";
    res.data.fechaVencimiento = res.data.fechaVencimiento
      ? res.data.fechaVencimiento.split("T")[0]
      : "";
    dispatch({
      type: OBTENER_PRODUCTO,
      payload: res.data,
    });
  } catch (error) {
    dispatch(setErrorAlert(error));
  }
};

export const eliminarProducto = (id) => async (dispatch) => {
  try {
    await axios.delete("/productos/" + id);
    dispatch(
      setAlert({ tipo: "success", msj: "El producto eliminado con exito" })
    );
  } catch (error) {
    dispatch(setErrorAlert(error));
  }
};

export const limpiarProducto = () => async (dispatch) => {
  try {
    dispatch({
      type: LIMPIAR_PRODUCTO,
      payload: newProducto,
    });
  } catch (error) {
    dispatch(setErrorAlert(error));
  }
};
