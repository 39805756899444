/*eslint-disable*/
import React from "react";

// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
import Table from "components/Table/Table.js";

// redux
import { useDispatch } from "react-redux";
import { anularFactura, obtenerPDF, anularVenta, obtenerVentas } from "../../redux/ventaDucks";

// utils
import { getNewDate } from '../../utils/utils'


// style for this view
import styles1 from "assets/jss/material-dashboard-pro-react/modalStyle.js";
import styles2 from "assets/jss/material-dashboard-pro-react/components/customInputStyle.js";
import styles3 from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import styles4 from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

const useStyles = makeStyles({
    ...styles1,
    ...styles2,
    ...styles3,
    ...styles4,
});

export default function ModalFacturacionDetalle({ tipo, color = "Info", closeModal, dataTable }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [alert, setAlert] = React.useState(null);

    const actualizarVentas = () => {
        dispatch(obtenerVentas())
        closeModal(false)
    }

    const getForm = () => {
        return (
            <>
                <GridContainer>
                    {tipo === 1 ? null : <><GridItem xs={6}>
                        {inpuFormInt()}
                    </GridItem><GridItem xs={6}>
                            {inpuFormNombre()}
                        </GridItem></>}
                    <GridItem xs={12}>
                        <Card >
                            <CardBody style={{ height: "54vh" }}>
                                <Table
                                    striped
                                    tableHead={[
                                        "#",
                                        "Producto",
                                        "cantidad",
                                        "Precio",
                                        "Total del producto",
                                    ]}
                                    tableData={dataTable.data}
                                    customCellClasses={[classes.center, classes.right, classes.right]}
                                    customClassesForCells={[0, 4, 5]}
                                    customHeadCellClasses={[
                                        classes.center,
                                        classes.right,
                                        classes.right,
                                    ]}
                                    customHeadClassesForCells={[0, 4, 5]}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer >
            </>
        )
    }

    const hideAlert = () => {
        setAlert(null);
    };

    const inpuFormInt = () => (
        <GridContainer>
            <GridItem style={{ textAlign: 'right', marginTop: '35px' }} xs={12} sm={3}>
                <FormLabel>
                    Nit
                </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={8}>
                <TextField
                    fullWidth
                    className={classes.formControl}
                    InputProps={{
                        className: classes["input" + color],
                        id: 'nit',
                        name: 'nit',
                        value: dataTable.nit,
                    }}
                    variant="standard"
                    type={'text'}
                />
            </GridItem>
        </GridContainer>
    )

    const inpuFormNombre = () => (
        <GridContainer>
            <GridItem style={{ textAlign: 'right', marginTop: '35px' }} xs={12} sm={3}>
                <FormLabel>
                    Nombre
                </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={8}>
                <TextField
                    fullWidth
                    className={classes.formControl}
                    InputProps={{
                        className: classes["input" + color],
                        id: 'name',
                        name: 'name',
                        value: dataTable.nombre,
                    }}
                    variant="standard"
                    type={'text'}
                />
            </GridItem>
        </GridContainer>
    )

    const imprimir = () => {
        dispatch(obtenerPDF(dataTable.uuid, dataTable.nombre))
    }

    const realizarAnulacion = (e) => {
        const data = {
            uuid: dataTable.uuid,
            uuidEmision: dataTable.uuidEmision,
            nit: dataTable.nit,
            fechaEmicion: dataTable.fecha,
            fechaAnulacion: getNewDate(),
            motivo: e,
            id: dataTable.id,
            productos: dataTable.productos,
        }
        dispatch(anularFactura(data, actualizarVentas))
        setAlert(
            <SweetAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title="Completado"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnCssClass={classes.button + " " + classes.success}
            >
                Factura Anulada
            </SweetAlert>
        );
    };

    const realizarAnulacionVenta = () => {
        const data = {
            uuid: dataTable.uuid,
            uuidEmision: dataTable.uuidEmision,
            nit: dataTable.nit,
            fechaEmicion: dataTable.fecha,
            fechaAnulacion: getNewDate(),
            motivo: "",
            id: dataTable.id,
            productos: dataTable.productos,
            anulado: dataTable.anulado
        }

        dispatch(anularVenta(data, actualizarVentas))
        setAlert(
            <SweetAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title="Completado"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnCssClass={classes.button + " " + classes.success}
            >
                Factura Anulada
            </SweetAlert>
        );
    };

    const cancelarAnulacion = () => {
        setAlert(
            <SweetAlert
                danger
                style={{ display: "block", marginTop: "-100px" }}
                title="Cancelado"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnCssClass={classes.button + " " + classes.success}
            >
                Se cancelo la anulacion de la factura
            </SweetAlert>
        );
    };

    const anularFacturas = () => {
        setAlert(
            <SweetAlert
                input
                showCancel
                style={{ display: "block", marginTop: "-100px" }}
                title="Anular Factura"
                onConfirm={(e) => realizarAnulacion(e)}
                onCancel={() => cancelarAnulacion()}
                confirmBtnCssClass={classes.button + " " + classes.success}
                cancelBtnCssClass={classes.button + " " + classes.danger}
                confirmBtnText="Anular"
                cancelBtnText="Cancelar"
            >
                Ingrese el motivo de la anulacion de la factura
            </SweetAlert>
        );
    };

    const errorAnulacion = (msj) => {
        setAlert(
            <SweetAlert
                style={{ display: "block", marginTop: "-100px" }}
                title="Error"
                onConfirm={() => hideAlert()}
                confirmBtnCssClass={classes.button + " " + classes.success}
                confirmBtnText="OK"
            >
                {msj}
            </SweetAlert>
        );
    };

    const activarModalAnulacion = () => {
        if (dataTable.anulado) {
            errorAnulacion("La factura ya se encuentra anulada")
        }
        else if (dataTable.nit === 'NF') {
            realizarAnulacionVenta()
        }
        else {
            anularFacturas()
        }
    }

    return (
        <>
            {alert}
            <CardBody>
                {getForm()}
            </CardBody>
            <CardFooter >
                <GridContainer>
                    <GridItem style={{ marginLeft: "auto" }} />
                    <GridItem style={{ marginLeft: "10px" }}>
                        {tipo === 1 ? null :
                            <>
                                <Button style={{ marginLeft: "auto" }} color={color.toLowerCase()} onClick={() => activarModalAnulacion()}>
                                    Anular venta
                                </Button>
                                <Button style={{ marginLeft: "auto" }} color={color.toLowerCase()} onClick={() => imprimir()}>
                                    Descargar PDF
                                </Button>
                            </>
                        }
                        <Button color="danger" onClick={() => { closeModal(false) }}>
                            Cerrar
                        </Button>
                    </GridItem>
                </GridContainer>
            </CardFooter>
        </>
    );
}
