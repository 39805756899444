import React, { useMemo } from "react";
import { useCookies } from "react-cookie";

import {
  Route,
  Routes,
  Navigate,
  useNavigate,
} from "react-router-dom";

import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";

import "assets/scss/material-dashboard-pro-react.scss?v=1.10.0";

// redux
import { useDispatch } from "react-redux";
import { validateSession } from "./redux/sessionDucks";

const Main = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [cookies] = useCookies(["session"]);

  useMemo(() => {
    dispatch(validateSession(cookies, navigate));
  }, []);

  return (
    <Routes>
      <Route
        index
        element={<Navigate to="/auth" replace />}
      />
      <Route index path="/auth/*" element={<AuthLayout />} />
      <Route path="/admin/*" element={<AdminLayout />} />
    </Routes>
  );
}

export default Main;