/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
import Table from "components/Table/Table.js";

// style for this view
import styles1 from "assets/jss/material-dashboard-pro-react/modalStyle.js";
import styles2 from "assets/jss/material-dashboard-pro-react/components/customInputStyle.js";
import styles3 from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

//utils
import { getNewDate } from '../../utils/utils'

// validate imputs
import { useDispatch, useSelector } from "react-redux";
import { confirmarCompra } from "../../redux/compraDucks.js";
import { confirmarVenta, confirmarVentaNit, obtenerInformacionCliente, facturarSinVenta } from "../../redux/ventaDucks.js";
import { obtenerProductosCP } from "../../redux/productoDucks";

const useStyles = makeStyles({
    ...styles1, ...styles2, ...styles3
});

export default function ModalFacturacion({ tipo, color = "Info", closeModal, dataTable }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const compra = useSelector((store) => store.compras.compra);
    const venta = useSelector((store) => store.ventas.venta);
    const [nit, setNit] = React.useState('');
    const [nombre, setNombre] = React.useState('');
    const [direccion, setDireccion] = React.useState('');
    const [error, setError] = React.useState('');
    const [disab, setDisab] = React.useState(false);

    const actualizarProductos = () => {
        dispatch(obtenerProductosCP())
    }

    const facturar = () => {
        const nitAux = nit.trim();
        const nombreAux = nombre.trim();
        setError('')

        if ((nitAux.length < 5) && nitAux !== "CF") {
            setError("NIT invalido")
            return
        }
        if (nitAux !== "CF" && error === 'Nit invalido') {
            setError("NIT invalido")
            return
        }
        if (nombreAux === "") {
            setError("El nombre no puede estar vacio")
            return
        }
        if (nitAux === "") {
            setError("El nit no puede estar vacio")
            return
        }

        if (error !== '') {
            setNit(nitAux)
            setNombre(nombreAux)
            return;
        }

        setDisab(true)
        dispatch(facturarSinVenta(venta, nitAux, nombreAux, getNewDate(), direccion, closeModal));
    }

    const onSubmit = (e) => {
        e.preventDefault();
        const nitAux = nit.trim();
        const nombreAux = nombre.trim();
        setError('')

        if (nombreAux === "") {
            setError("El nombre no puede estar vacio")
            return
        }
        if (nitAux === "") {
            setError("El nit no puede estar vacio")
            return
        }

        if (error !== '') {
            setNit(nitAux)
            setNombre(nombreAux)
            return;
        }

        setDisab(true)
        dispatch(confirmarVentaNit(venta, nitAux, nombreAux, getNewDate(), direccion, closeModal, actualizarProductos));
    }

    const confirmar = () => {
        setDisab(true)
        if (tipo === 1) {
            dispatch(confirmarCompra(compra, getNewDate(), closeModal))
        } else {
            dispatch(confirmarVenta(venta, getNewDate(), closeModal, actualizarProductos))
        }
    }

    const getForm = () => {
        return (
            <>
                <GridContainer>
                    {tipo === 1 ? null : <><GridItem xs={6}>
                        {nitForm()}
                    </GridItem><GridItem xs={6}>
                            {nombreForm()}
                        </GridItem></>
                    }
                    <GridItem style={{ textAlign: 'center' }} xs={12}>
                        {direccion ? `Direccion ${direccion}` : null}
                    </GridItem>
                    <GridItem style={{ textAlign: 'center' }} xs={12}>
                        <label style={{ color: 'red' }}>{error}</label></GridItem>
                    <GridItem xs={12}>
                        <Card style={{ height: "55vh" }}>
                            <CardBody>
                                <Table
                                    striped
                                    tableHead={[
                                        "#",
                                        "Producto",
                                        "cantidad",
                                        "Precio",
                                        "Total del producto",
                                    ]}
                                    tableData={dataTable}
                                    customCellClasses={[classes.center, classes.right, classes.right]}
                                    customClassesForCells={[0, 4, 5]}
                                    customHeadCellClasses={[
                                        classes.center,
                                        classes.right,
                                        classes.right,
                                    ]}
                                    customHeadClassesForCells={[0, 4, 5]}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer >
            </>
        )
    }

    const updateNit = (e) => {
        setError('')
        if (e.target.value.toLowerCase() === 'cf') {
            setNombre("Consumidor Final")
            setNit("CF")
        }
        else if (e.target.value.toLowerCase() === 'c') {
            setNit(e.target.value)
            setNombre("")
        }
        else {
            const nitTrim = e.target.value.trim()
            setNit(nitTrim.toUpperCase())
            dispatch(obtenerInformacionCliente(nitTrim.toUpperCase(), setNombre, setDireccion, setError))
        }
    }

    const nitForm = () => (
        <GridContainer>
            <GridItem style={{ textAlign: 'right', marginTop: '35px' }} xs={12} sm={3}>
                <FormLabel>
                    NIT
                </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={8}>
                <TextField
                    fullWidth
                    className={classes.formControl}
                    InputProps={{
                        className: classes["input" + color],
                        onChange: (e) => updateNit(e),
                        id: "nit",
                        name: "nit",
                        value: nit
                    }}
                    variant="standard"
                    type={"text"}
                />
            </GridItem>
        </GridContainer>
    )

    const nombreForm = () => (
        <GridContainer>
            <GridItem style={{ textAlign: 'right', marginTop: '35px' }} xs={12} sm={3}>
                <FormLabel>
                    Nombre
                </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={8}>
                <TextField
                    fullWidth
                    className={classes.formControl}
                    InputProps={{
                        className: classes["input" + color],
                        onChange: (e) => setNombre(e.target.value),
                        id: "nombre",
                        name: "nombre",
                        value: nombre,
                    }}
                    variant="standard"
                    type={"text"}
                />
            </GridItem>
        </GridContainer>
    )

    return (
        <form onSubmit={onSubmit}>
            <CardBody>
                {getForm()}
            </CardBody>
            <CardFooter >
                {tipo === 1 ? null :
                    <>
                        <Button disabled={disab} color={color.toLowerCase()} onClick={() => facturar()}>
                            Factura sin venta
                        </Button>
                        <Button style={{ marginLeft: "auto" }} disabled={disab} color={color.toLowerCase()} type="submit">
                            Cofirmar y facturar
                        </Button>
                    </>
                }
                <Button style={{ marginLeft: tipo === 1 ? "auto" : "10px" }} disabled={disab} onClick={() => confirmar()} color={color.toLowerCase()}>
                    Confirmar
                </Button>
                <Button style={{ marginLeft: "10px" }} color="danger" onClick={() => closeModal()}>
                    Cancelar
                </Button>
            </CardFooter>
        </form>
    );
}
