/*eslint-disable*/
import axios from "axios";
import { setErrorAlert } from "./alertDuck";
import { utils, write } from 'xlsx';

const dataInicial = {
  reportesDiarios: {
    ventasDiarias: 0,
    cantidadVentas: 0,
    cantidadVentasEfectivo: 0,
    utilidadPorDia: 0,
    facturadoDiario: 0,
    efectivoDiario: 0,
  },
  top10: {},
  top10ABC: { grapA: {}, grapB: {}, grapC: {} },
  ventasMes: {},
  productos: "",
};

const REPORTES_DIAROS = "REPORTES_DIAROS";
const TOP10_REPORTES = "TOP10_REPORTES";
const TOP10_REPORTESABC = "TOP10_REPORTESABC";
const VENTASPORMES_REPORTES = "VENTASPORMES_REPORTES";
const PRODCUTOS_REPORTES = "PRODCUTOS_REPORTES";

export default function reportesReducer(state = dataInicial, action) {
  switch (action.type) {
    case REPORTES_DIAROS:
      return { ...state, reportesDiarios: action.payload };
    case TOP10_REPORTES:
      return { ...state, top10: action.payload };
    case TOP10_REPORTESABC:
      return { ...state, top10ABC: action.payload };
    case VENTASPORMES_REPORTES:
      return { ...state, ventasMes: action.payload };
    case PRODCUTOS_REPORTES:
      return { ...state, productos: action.payload };
    default:
      return state;
  }
}

export const obtenerReportesDiarios = (date) => async (dispatch) => {
  try {
    let res = await axios.get("/reportes/ReportedDiarios?date=" + date);
    dispatch({
      type: REPORTES_DIAROS,
      payload: res.data,
    });
  } catch (error) {
    dispatch(setErrorAlert(error));
  }
};

const getFormatDate = (date) => {
  if (date === '') return ''
  const dateSplit = date.split('-');
  return `${dateSplit[2]}/${dateSplit[1]}/${dateSplit[0]}`
}

export const generarReporte = (tipo, date1, date2) => async (dispatch) => {
  try {
    if (date1 === '') {
      throw { message: 'Se debe de elegir una fecha para generar el reporte' }
    }
    if (date2 === '' && tipo === 4) {
      throw { message: 'Se debe elegir fecha final para generar el reporte' }
    }
    let res = await axios.get(`/reportes/GenerarReporte?tipo=${tipo}&date1=${getFormatDate(date1)}&date2=${getFormatDate(date2)}`);
    let a = document.createElement("a");
    a.href = res.data.pdf;
    a.download = `Reporte.pdf`
    a.click();
  } catch (error) {
    dispatch(setErrorAlert(error));
  }
};

export const generarReporteSinFecha = (tipo) => async (dispatch) => {
  try {
    let res = await axios.get(`/reportes/GenerarReporte?tipo=${tipo}`);
    let a = document.createElement("a");
    a.href = res.data.pdf;
    a.download = `reporte.pdf`
    a.click();
  } catch (error) {
    dispatch(setErrorAlert(error));
  }
};

export const obtenerTop10 = (date) => async (dispatch) => {
  try {
    let res = await axios.get("/reportes/top10?date=" + date);
    dispatch({
      type: TOP10_REPORTES,
      payload: res.data,
    });
  } catch (error) {
    dispatch(setErrorAlert(error));
  }
};

export const obtenerVentasMes = (date) => async (dispatch) => {
  try {
    let res = await axios.get("/reportes/SalesByMonth?date=" + date);
    dispatch({
      type: VENTASPORMES_REPORTES,
      payload: res.data,
    });
  } catch (error) {
    dispatch(setErrorAlert(error));
  }
};

export const obtenerTop10ABC = (date) => async (dispatch) => {
  try {
    let res = await axios.get("/reportes/top10ABC?date=" + date);
    dispatch({
      type: TOP10_REPORTESABC,
      payload: res.data,
    });
  } catch (error) {
    dispatch(setErrorAlert(error));
  }
};

export const obtenerProductos = () => async (dispatch) => {
  try {
    let res = await axios.get("/reportes/inventoryExcel");

    const arrayOfArrayCsv = res.data.csv.split("\n").map((row) => {
      return row.split(",")
    });
    const wb = utils.book_new();
    const newWs = utils.aoa_to_sheet(arrayOfArrayCsv);
    utils.book_append_sheet(wb, newWs);
    const rawExcel = write(wb, { type: 'base64' })
    let a = document.createElement("a");
    a.href = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + rawExcel;
    a.download = `reporte.xlsx`
    a.click();
  } catch (error) {
    dispatch(setErrorAlert(error));
  }
};