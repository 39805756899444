/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";

// style for this view
import styles1 from "assets/jss/material-dashboard-pro-react/modalStyle.js";
import styles2 from "assets/jss/material-dashboard-pro-react/components/customInputStyle.js";

import { useFormik } from 'formik';
import { useDispatch, useSelector } from "react-redux";
import { nuevoProveedor, editarProveedor } from "../../redux/proveedorDucks.js";

import { proveedorSchema } from "../../utils/schemas";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles({
    ...styles1, ...styles2,
});

export default function ModalProveedoresForm({ tipo, color = "Info", closeModal }) {
    const dispatch = useDispatch();
    const classes = useStyles();

    const proveedor = useSelector((store) => store.proveedores.proveedor);

    const formik = useFormik({
        initialValues: proveedor,
        enableReinitialize: true,
        validationSchema: proveedorSchema,
        onSubmit: (values) => {
            const dataTemp = {
                nombre: values.nombre,
                correo: values.correo,
                celular: values.celular,
                empresa: values.empresa,
                descripcion: values.descripcion,
            }
            if (tipo) dispatch(nuevoProveedor(dataTemp, closeModal))
            else dispatch(editarProveedor(dataTemp, proveedor._id))
        },
    });

    const getForm = () => {
        return (
            <>
                <GridContainer>
                    <GridItem xs={12} sm={6}>
                        {inpuForm("Nombre", "text", "nombre")}
                        {inpuForm("Celular", "text", "celular")}
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                        {inpuForm("Empresa", "text", "empresa")}
                        {inpuForm("Correo", "email", "correo")}
                    </GridItem>
                </GridContainer >
                {inpuForm("Descripcion", "text", "descripcion")}
            </>
        )
    }
    
    const inpuForm = (name, type, id) => (
        <GridContainer>
            <GridItem style={{ textAlign: 'right', marginTop: '35px' }} xs={12} sm={3}>
                <FormLabel>
                    {name}
                </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={8}>
                <TextField
                    fullWidth
                    className={classes.formControl}
                    InputProps={{
                        className: classes["input" + color],
                        onChange: formik.handleChange,
                        id: id,
                        name: id,
                        value: formik.values[id] !== null ? formik.values[id] : "",
                    }}
                    error={formik.touched[id] && Boolean(formik.errors[id])}
                    helperText={formik.touched[id] && formik.errors[id]}
                    variant="standard"
                    type={type}
                />
            </GridItem>
        </GridContainer>
    )

    return (
        <form onSubmit={formik.handleSubmit}>
            <CardBody>
                {getForm()}
            </CardBody>
            <CardFooter >
                <Button style={{ marginLeft: "auto" }} color={color.toLowerCase()} type="submit">
                    {tipo ? "Crear" : "Guardar"}
                </Button>
                <Button style={{ marginLeft: "10px" }} color="danger" onClick={() => closeModal()}>
                    Cancelar
                </Button>
            </CardFooter>
        </form>
    );
}
