/*eslint-disable*/
import React from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

import { Line, Bar } from 'react-chartjs-2';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Icon from "@material-ui/core/Icon";
import TextField from "@material-ui/core/TextField";
import FormLabel from "@material-ui/core/FormLabel";

// @material-ui/icons
// import ContentCopy from "@material-ui/icons/ContentCopy";
import Store from "@material-ui/icons/Store";
import Timeline from "@material-ui/icons/Timeline";
import CreditCardIcon from '@material-ui/icons/CreditCard';
import LocalAtm from '@material-ui/icons/LocalAtm';
import AddToPhotos from '@material-ui/icons/AddToPhotos';
import Receipt from "@material-ui/icons/Receipt";
import PostAdd from "@material-ui/icons/PostAdd";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import {
  emailsSubscriptionChart,
  completedTasksChart,
} from "variables/charts";

import { useDispatch, useSelector } from "react-redux";
import {
  obtenerTop10,
  obtenerTop10ABC,
  obtenerVentasMes,
  obtenerReportesDiarios,
  generarReporte,
  generarReporteSinFecha,
  obtenerProductos
} from "../../redux/reportesDucks";

//utilitys

import { getNewDate, getMoneyFormat } from "../../utils/utils"

import styles1 from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import styles2 from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";
import styles3 from "assets/jss/material-dashboard-pro-react/components/customInputStyle.js";

const useStyles1 = makeStyles(styles1);
const useStyles2 = makeStyles({ ...styles2, ...styles3 });

export default function Dashboard() {
  const dispatch = useDispatch();
  const classes = useStyles1();
  const classes2 = useStyles2();
  const {
    reportesDiarios,
    top10,
    top10ABC,
    ventasMes,
  } = useSelector((store) => store.reportes);

  const [fecha, setFecha] = React.useState('');
  const [fechaInicio, setFechaInicio] = React.useState('');
  const [fechaFin, setFechaFin] = React.useState('');

  React.useEffect(() => {
    const date = getNewDate();
    let splitDate = date.split('T')
    setFecha(splitDate[0])
    loadReports(splitDate[0])
  }, [dispatch]);

  const changueDate = (date) => {
    loadReports(date)
    setFecha(date)
  }

  const onClickReport = (tipo) => {
    dispatch(generarReporte(tipo, fechaInicio, fechaFin))
  }
  const onClickReportSinFecha = () => {
    dispatch(obtenerProductos())
  }

  const loadReports = (date) => {
    const auxDate = date.split('-')
    const newDate = `${auxDate[2]}/${auxDate[1]}/${auxDate[0]}`
    const newMonth = `${auxDate[1]}/${auxDate[0]}`
    dispatch(obtenerReportesDiarios(newDate))
    dispatch(obtenerTop10(newMonth))
    dispatch(obtenerTop10ABC(newMonth))
    dispatch(obtenerVentasMes(newMonth))
  }
  const optionsLine = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Ventas durante el mes',
      },
    },
  };

  const optionsBarA = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Productos A',
      },
    },
  };
  const optionsBarB = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Productos B',
      },
    },
  };  const optionsBarC = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Productos C',
      },
    },
  };
  const getDataMes = (newData) => {
    if (newData.series != undefined) {
      return {
        labels: newData.labels,
        datasets: [
          {
            label: 'Ventas',
            data: newData.series[0],
            borderColor: 'rgb(55, 194, 255  )',
            backgroundColor: 'rgba(55, 194, 255 , 1)',
          },
          {
            label: 'Utilidades',
            data: newData.series[1],
            borderColor: 'rgb(55, 255, 64  )',
            backgroundColor: 'rgba(55, 255, 64  , 1)',
          },
        ],
      };
    }
    return { labels: [], datasets: [] }
  }

  const getDataBar = (newData) => {
    if (newData.series != undefined) {
      return {
        labels: newData.labels,
        datasets: [
          {
            label: 'Producto',
            data: newData.series[0],
            borderColor: 'rgb(255, 255, 255 )',
            backgroundColor: 'rgba(255, 255, 255 , 1)',
          },
        ],
      };
    }
    return { labels: [], datasets: [] }
  }

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader>
              <br />
              Graficos
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem style={{ textAlign: 'right', marginTop: '35px' }} xs={12} sm={3}>
                  <FormLabel>
                    Fecha
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={8}>
                  <TextField
                    fullWidth
                    className={classes2.formControl}
                    InputProps={{
                      className: classes2["inputInfo"],
                      onChange: (e) => changueDate(e.target.value),
                      value: fecha,
                    }}
                    value={fecha}
                    variant="standard"
                    type={'date'}
                  >
                  </TextField>
                </GridItem>
              </GridContainer>
              <br />
              <br />
              <GridContainer style={{ justifyContent: 'center' }}>
                <Button color="success" onClick={() => onClickReportSinFecha(7)}>
                  Listado de productos
                </Button>
              </GridContainer>
            </CardBody>
            <CardFooter stats>
              <div className={classes.stats}>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={9}>
          <Card>
            <CardHeader >
              <br />
              Generacion de reportes
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={6}>
                  <GridContainer>
                    <GridItem style={{ textAlign: 'right', marginTop: '35px' }} xs={12} sm={3}>
                      <FormLabel>
                        Fecha Inicio
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={8}>
                      <TextField
                        fullWidth
                        className={classes2.formControl}
                        InputProps={{
                          className: classes2["inputInfo"],
                          onChange: (e) => setFechaInicio(e.target.value),
                          value: fechaInicio,
                        }}
                        value={fechaInicio}
                        variant="standard"
                        type={'date'}
                      >
                      </TextField>
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem xs={6}>
                  <GridContainer>
                    <GridItem style={{ textAlign: 'right', marginTop: '35px' }} xs={12} sm={3}>
                      <FormLabel>
                        Fecha Fin
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={8}>
                      <TextField
                        fullWidth
                        className={classes2.formControl}
                        InputProps={{
                          className: classes2["inputInfo"],
                          onChange: (e) => setFechaFin(e.target.value),
                          value: fechaFin,
                        }}
                        value={fechaFin}
                        variant="standard"
                        type={'date'}
                      >
                      </TextField>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
              <GridContainer style={{ justifyContent: 'center' }}>
                <Button color="primary" onClick={() => onClickReport(1)}>
                  Diario
                </Button>
                <Button color="info" onClick={() => onClickReport(2)}>
                  Mensual
                </Button>
                <Button color="success" onClick={() => onClickReport(3)}>
                  Anual
                </Button>
                <Button color="warning" onClick={() => onClickReport(4)}>
                  Periodo definido
                </Button>
              </GridContainer>
              <GridContainer style={{ justifyContent: 'center' }}>
                <Button color="rose" onClick={() => onClickReportSinFecha(5)}>
                  Productos agoados/por agotar
                </Button>
                <Button color="danger" onClick={() => onClickReportSinFecha(6)}>
                  Productos vencidos/por vencer
                </Button>
              </GridContainer>
            </CardBody>
            <CardFooter stats>
              <div className={classes.stats}>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <Store />
              </CardIcon>
              <p className={classes.cardCategory}>Ventas</p>
              <h3 className={classes.cardTitle}>
                {getMoneyFormat(reportesDiarios.ventasDiarias)}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <Timeline />
              </CardIcon>
              <p className={classes.cardCategory}>Utilidades</p>
              <h3 className={classes.cardTitle}>{getMoneyFormat(reportesDiarios.utilidadPorDia)}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <Icon>info_outline</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Total sin facturar</p>
              <h3 className={classes.cardTitle}>{getMoneyFormat(reportesDiarios.ventasDiarias - reportesDiarios.facturadoDiario)}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <Receipt />
              </CardIcon>
              <p className={classes.cardCategory}>Total facturado</p>
              <h3 className={classes.cardTitle}>{getMoneyFormat(reportesDiarios.facturadoDiario)}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="primary" stats icon>
              <CardIcon color="primary">
                <LocalAtm />
              </CardIcon>
              <p className={classes.cardCategory}>Dinero en efectivo</p>
              <h3 className={classes.cardTitle}>{getMoneyFormat(reportesDiarios.efectivoDiario)}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="rose" stats icon>
              <CardIcon color="rose">
                <CreditCardIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Dinero en tarjeta</p>
              <h3 className={classes.cardTitle}>{getMoneyFormat(reportesDiarios.ventasDiarias - reportesDiarios.efectivoDiario)}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <PostAdd />
              </CardIcon>
              <p className={classes.cardCategory}>Cantidad de ventas en efectivo</p>
              <h3 className={classes.cardTitle}>{reportesDiarios.cantidadVentasEfectivo}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <AddToPhotos />
              </CardIcon>
              <p className={classes.cardCategory}>Cantidad ventas con tarjeta</p>
              <h3 className={classes.cardTitle}>{reportesDiarios.cantidadVentas - reportesDiarios.cantidadVentasEfectivo}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card chart className={classes.cardHover}>
            <CardHeader color="white">
              <Line data={getDataMes(ventasMes)} options={optionsLine} />
            </CardHeader>
            <CardFooter chart>
              <div className={classes.stats}>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card chart className={classes.cardHover}>
            <CardHeader color="success">
              <Bar data={getDataBar(top10ABC.grapA)} options={optionsBarA} />
            </CardHeader>
            <CardFooter chart>
              <div className={classes.stats}>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        
        <GridItem xs={12} sm={12} md={6}>
          <Card chart className={classes.cardHover}>
            <CardHeader color="info">
              <Bar data={getDataBar(top10ABC.grapB)} options={optionsBarB} />
            </CardHeader>
            <CardFooter chart>
              <div className={classes.stats}>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        
        <GridItem xs={12} sm={12} md={6}>
          <Card chart className={classes.cardHover}>
            <CardHeader color="warning">
              <Bar data={getDataBar(top10ABC.grapC)} options={optionsBarC} />
            </CardHeader>
            <CardFooter chart>
              <div className={classes.stats}>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div >
  );
}
