/*eslint-disable*/
import React from "react";

// @material-ui/icons
import ReceiptTwoTone from "@material-ui/icons/ReceiptTwoTone";

// core components
import ListaCV from "./ListadoCV.js";

// redux
import { useDispatch, useSelector } from "react-redux";
import { obtenerCompras } from "../redux/compraDucks";

export default function Ventas() {
  const dispatch = useDispatch();
  const ventas = useSelector((store) => store.compras.compras);
  const header = ["NIT", "Nombre", "Cantidad", "Fecha Venta" ];
  
  React.useEffect(() => {
    dispatch(obtenerCompras());
  }, [dispatch]);

  return (
    <ListaCV 
      filtrar={obtenerCompras}
      Icon={ReceiptTwoTone}
      color={"Primary"}
      header={header}
      data={ventas}
      type={1}
    />
  );
}
