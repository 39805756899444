/*eslint-disable*/
import React from "react";

// @material-ui/icons
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";

// core components
import Button from "components/CustomButtons/Button.js";

export default function ActionRows ({ color = "Info", func, activateModal, classes }) {
    return (_id) =>{
        return (<div className="actions-center">
            <Button
                onClick={() => activateModal(color, _id)}
                color={color.toLowerCase()}
                className={classes.actionButton}
            >
                <Edit className={classes.icon}/>
            </Button>
            <Button
                style={{ marginLeft: `20px` }}
                onClick={() => func(_id)}
                color="danger"
                className={classes.actionButton}
            >
                <Close className={classes.icon} />
            </Button>{" "}
        </div>)
    };
}
