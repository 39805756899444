/*eslint-disable*/
import React from "react";
import {
  useTable,
  useFilters,
  useSortBy,
  usePagination,
} from "react-table";
import classnames from "classnames";
// A great library for fuzzy filtering/sorting items
import { matchSorter } from "match-sorter";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons icons
import Search from "@material-ui/icons/Search";
// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import InputAdornment from "@material-ui/core/InputAdornment";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles1 from "assets/jss/material-dashboard-pro-react/customSelectStyleInfo.js";
import styles2 from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import styles3 from "assets/jss/material-dashboard-pro-react/components/customInputStyle.js";

const newStyles = {
  ...styles1,
  ...styles2,
  ...styles3,
  formControlMargins: {
    margin: "3px 0 !important",
  },
  gridContainer: {
    justifyContent: "center",
  },
};

const useStyles = makeStyles(newStyles);

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;
  //setFilter("edgar");
  return (
    <CustomInput
      formControlProps={{
        fullWidth: true,
      }}
      inputProps={{
        value: filterValue || "",
        onChange: (e) => {
          setFilter(""); // Set undefined to remove the filter entirely
        },
        placeholder: `Search ${count} records...`,
      }}
    />
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Our table component
function Table({ columns, data, selectProducto, addProduct, color }) {

  const [selectData, setSelectData] = React.useState("");
  const [numberOfRows, setNumberOfRows] = React.useState(300);
  const [filterDataTable, setFilterDataTable] = React.useState(data);
  const classes = useStyles();
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  React.useEffect(() => {
    setFilterDataTable(data)
  }, [data]);

  const filter = (value) => {
    setSelectData(value)
    const validate = (item, data) => {
      const dataLower = data.toLowerCase()
      const isTrue = (item.nombre.toLowerCase().includes(dataLower)) ||
        (item.descripcion !== null ? item.descripcion.toLowerCase().includes(dataLower) : false) ||
        (item.codigoBarras ? item.codigoBarras.includes(dataLower) : false)
      return isTrue
    }

    setFilterDataTable(data.filter(item => validate(item, value)))
  }

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
  } = useTable(
    {
      columns,
      data: filterDataTable,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: { pageSize: 50, pageIndex: 0 },
    },
    useFilters, // useFilters!
    useSortBy,
    usePagination
  );

  return (
    <>
      <div style={{ height: "60vh" }} className="ReactTable -striped -highlight">
        <div className="pagination-top">
        </div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={4} md={4}>
            <CustomInput
              id="search"
              formControlProps={{
                fullWidth: true,
              }}
              onFocus={(event) => event.target.select()}
              inputProps={{
                onFocus:(event) => event.target.select(),
                className: classes["input" + color],
                value: selectData,
                onChange: (event) => filter(event.target.value),
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <Search
                      className={classes["inputAdornmentIcon" + color]}
                    />
                  </InputAdornment>
                ),
              }}
              info
            >
            </CustomInput>
          </GridItem>
        </GridContainer>
        <table style={{ marginTop: `20px` }} {...getTableProps()} className="rt-table">
          <thead className="rt-thead -header">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} className="rt-tr">
                {headerGroup.headers.map((column, key) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={classnames("rt-th rt-resizable-header", {
                    })}
                  >
                    <GridContainer>
                      <GridItem>
                        <div>
                          {column.render("Header")}
                        </div>
                      </GridItem>
                    </GridContainer>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="rt-tbody">
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  onClick={() => { setSelectData(row.original.nombre), selectProducto(row.original) }}
                  {...row.getRowProps()}
                  className={classnames(
                    "rt-tr",
                    { " -odd": i % 2 === 0 },
                    { " -even": i % 2 === 1 }
                  )}
                >
                  {row.cells.map((cell) => {
                    const validateFields = () => {
                      if ("precioCosto" === cell.column.id || "precioVenta" === cell.column.id)
                        return true;
                      return false;
                    };
                    const getNewFormat = () => {
                      const newValue = (cell.row.original[cell.column.id]).toLocaleString('es-MX', {
                        style: 'currency',
                        currency: 'USD',
                      }).replace("USD", "Q")
                      return newValue;
                    }
                    return (
                      <td {...cell.getCellProps()} className="rt-td">
                        {validateFields() ? getNewFormat() : cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="pagination-bottom"></div>
      </div>
    </>
  );
}

function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

filterGreaterThan.autoRemove = (val) => typeof val !== "number";

export default Table;
