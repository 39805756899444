/*eslint-disable*/
import React from "react";

// @material-ui/icons
import Cached from "@material-ui/icons/Cached";
import Close from "@material-ui/icons/Close";
import InputAdornment from "@material-ui/core/InputAdornment";
import Switch from "@material-ui/core/Switch";

// core components
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Success from "components/Typography/Success.js";
import Modal from "components/Modal/Modal.js";
import ModalFacturacion from "components/ModalForm/ModalFacturacion";

// redux
import { useDispatch } from "react-redux";
import { obtenerProveedoresUsuario } from "../../redux/proveedorDucks";

//Validator to inputs
import { useFormik } from "formik";
import { ComprasArraySchema } from "components/ComprasForm/schemas";

import { cancelarCompra } from "../../redux/compraDucks.js";
import { cancelarVenta } from "../../redux/ventaDucks.js";

// utilitis
import { getNewDate } from "../../utils/utils.js"

// styles
import styles1 from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import styles2 from "assets/jss/material-dashboard-pro-react/components/customInputStyle.js";
import styles3 from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";

const useStyles = makeStyles({ ...styles1, ...styles2, ...styles3 });

export default function ItemsConfirm({
  type,
  updateItems,
  compraVenta,
  items,
  Icon,
  color = "Info",
}) {
  const dispatch = useDispatch();

  const classes = useStyles();
  const [modal, setModal] = React.useState(false);
  const [dataTable, setDataTable] = React.useState([]);
  const [descripcion, setDescripcion] = React.useState("");
  const [typeCV, setTypeCV] = React.useState(true);

  const closeModal = () => {
    setModal(false);
  };

  const cancelar = () => {
    dispatch(updateItems([]));
    if (type === 1) {
      dispatch(cancelarCompra())
    } else {
      dispatch(cancelarVenta())
    }
  }
  
  const getMoneyFormat = (amount) => {
    return (amount).toLocaleString('es-MX', {
      style: 'currency',
      currency: 'USD',
    }).replace("USD", "Q");
  }

  const getDataTable = (data, total) => {
    return [
      ...data.map((item, index) => {
        return [
          index + 1,
          item.nombre,
          item.cantidad,
          type === 1 ? getMoneyFormat(parseFloat(item.precioCosto)) : getMoneyFormat(parseFloat(item.precioVenta)),
          type === 1
            ? getMoneyFormat(item.cantidad * item.precioCosto)
            : getMoneyFormat(item.cantidad * item.precioVenta),
        ];
      }),
      { total: true, colspan: "3", amount: getMoneyFormat(total) },
    ];
  };

  const getDate = () => {
    const date = new Date();
    const stringDate =
      date.toISOString().split("T")[0] + ":" + date.toLocaleTimeString("en-GB");

    return stringDate;
  };

  const getTotal = (items) => {
    return items.reduce(
      (sum, item) =>
        sum +
        parseFloat(
          item[type === 1 ? "precioCosto" : "precioVenta"]
        ) *
        parseFloat(item.cantidad),
      0.0
    )
  };

  const { values, errors, handleSubmit, setFieldValue } = useFormik({
    initialValues: items,
    enableReinitialize: true,
    validationSchema: ComprasArraySchema,
    onSubmit: () => {
      const total = (Math.round(getTotal(values) * 100) / 100).toFixed(2);
      const nuevaCompra = {
        fecha: getNewDate(),
        total: total,
        descripcion: descripcion,
        proveedor: null,
        nombre: "Cliente",
        nit: "NF",
        productos: values,
        tipoVenta: typeCV ? "efectivo" : "tarjeta"
      };
      setDataTable(getDataTable(values, total));
      setModal(true);
      dispatch(compraVenta(nuevaCompra));
    },
  });

  React.useEffect(() => {
      dispatch(obtenerProveedoresUsuario());
  }, [dispatch]);

  const actions = (index) => (
    <div className="actions-center">
      <Button
        style={{ marginLeft: `20px`, marginTop: `25px` }}
        onClick={() => {
          let newData = values;

          newData.splice(index, 1);
          setFieldValue(newData);
          dispatch(updateItems(newData));
        }}
        color="danger"
        className={classes.actionButton}
      >
        <Close className={classes.icon} />
      </Button>{" "}
    </div>
  );

  const getMoneyIcon = (type) => {
    if (type === "precioCosto" || type === "precioVenta") {
      return (
        <InputAdornment position="end" className={classes.inputAdornment}>
          Q{""}
        </InputAdornment>
      );
    } else {
      return null;
    }
  };

  const inpuForm = (id, index, value, type = "number") => (
    <TextField
      onFocus={(event) => event.target.select()}
      fullWidth
      key={index + id}
      className={classes["formControl" + color]}
      InputProps={{
        className: classes["input" + color],
        onChange: (e) => {
          setFieldValue(index + "." + id, e.target.value);
          values[index][id] = e.target.value;
          let newData = values;
          dispatch(updateItems(newData));
        },
        id: index + id,
        name: index + id,
        value: value !== null ? value : "",
        startAdornment: getMoneyIcon(id),
      }}
      label={""}
      error={errors[index] ? Boolean(errors[index][id]) : false}
      helperText={errors[index] ? errors[index][id] : ""}
      type={type}
    />
  );

  const setValue = (data, id, index) => {
    setFieldValue(index + "." + id, data.formattedValue.replace("-", ""));
    let newData = values;
    values[index][id] = data.formattedValue.replace("-", "");
    dispatch(updateItems(newData));
  };

  const costDisable = type === 1 ? true : false;
  const costSize = type === 1 ? 2 : 3;
  const getRow = (item, index) => {
    return (
      <GridContainer key={"itemList" + index}>
        <GridItem
          style={{ textAlign: "left", marginTop: "30px" }}
          xs={12}
          sm={costSize}
        >
          {item.nombre}
        </GridItem>
        <GridItem xs={12} sm={costSize}>
          {inpuForm("cantidad", index, item.cantidad)}
        </GridItem>
        {costDisable ? (
          <GridItem xs={12} sm={2}>
            {inpuForm("precioCosto", index, item.precioCosto)}
          </GridItem>
        ) : null}
        <GridItem xs={12} sm={costSize}>
          {inpuForm("precioVenta", index, item.precioVenta)}
        </GridItem>
        {costDisable ? (
          <GridItem xs={12} sm={2}>
            {inpuForm("fechaVencimiento", index, item.fechaVencimiento, "date")}
          </GridItem>
        ) : null}
        <GridItem xs={12} sm={1}>
          {actions(index)}
        </GridItem>
      </GridContainer>
    );
  };

  return (
    <>
      <Card style={{ height: "80vh", position: "sticky" }}>
        <CardHeader icon>
          <GridContainer justify="space-between">
            <GridItem style={{ marginLeft: `18px` }}>
              <CardIcon
                onClick={() => window.location.reload(false)}
                color={color.toLowerCase()}
              >
                <Icon className={classes.icon} />
              </CardIcon>
            </GridItem>
            <GridItem>
              <CardIcon onClick={() => cancelar()} color="success">
                <Cached className={classes.icon} />
              </CardIcon>
            </GridItem>
          </GridContainer>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={costSize}>
              Producto
            </GridItem>
            <GridItem xs={12} sm={costSize}>
              Cantidad
            </GridItem>
            {costDisable ? (
              <GridItem xs={12} sm={2}>
                Costo
              </GridItem>
            ) : null}
            <GridItem xs={12} sm={costSize}>
              Venta
            </GridItem>
            {costDisable ? (
              <GridItem xs={12} sm={3}>
                Vencimiento
              </GridItem>
            ) : null}
            <GridItem xs={12} sm={costSize === 1 ? 1 : 3} />
          </GridContainer>
          <form onSubmit={handleSubmit}>
            <GridContainer style={{ height: "53vh", overflowY: 'auto', overflowX: 'hidden', paddingTop: "15px", paddingLeft: "16px" }}>
              {values.map((item, index) => {
                return getRow(item, index);
              })}
            </GridContainer>
            <GridContainer>
              <GridItem xs={12}>
                <Card>
                  <CardBody
                    style={{ textAlign: "center", background: "#E2E2E2" }}
                  >
                    <GridContainer>
                      <GridItem xs={6}>
                        <TextField
                          style={{ marginTop: `-5px` }}
                          fullWidth
                          className={classes["formControl" + color]}
                          onChange={(e) => setDescripcion(e.target.value)}
                          InputProps={{
                            className: classes["input" + color],
                          }}
                          label="Nota"
                          type="text"
                        />
                      </GridItem>
                      <GridItem xs={6}>
                        <GridContainer style={{ marginTop: `15px` }}>
                          <GridItem xs={6}>
                            <Success> Total </Success>

                            {values.length && type === 2 ?
                              (<div style={{ marginTop: `25px` }}>
                                <label className={classes.label}>{typeCV ? "Efectivo" : "Tarjeta"}</label>
                                <Switch
                                  checked={typeCV}
                                  onChange={() => setTypeCV(!typeCV)}
                                  label="ADS"
                                  classes={{
                                    switchBase: classes.switchWarning,
                                    checked: classes.switchCheckedWarning,
                                    thumb: classes.switchIcon,
                                    track: classes.switchBar,
                                  }}
                                />
                              </div>
                              ) : null}
                          </GridItem>

                          <GridItem xs={6}>
                            <Success>
                              {"Q "}
                              {(Math.round(items.reduce(
                                (sum, item) =>
                                  sum +
                                  parseFloat(
                                    item[
                                    type === 1 ? "precioCosto" : "precioVenta"
                                    ]
                                  ) *
                                  parseFloat(item.cantidad),
                                0.0
                              ) * 100) / 100).toFixed(2)}{" "}
                            </Success>

                            {values.length ? (
                              <Button
                                color={"success"}
                                type="submit"
                                style={{ float: "right", marginTop: `25px`, marginRight: `25px` }}
                              >
                                {type === 1 ? "Comprar" : "Vender"}
                              </Button>
                            ) : null}
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12}>
                {" "}
              </GridItem>
            </GridContainer>
          </form>
        </CardBody>
      </Card>
      <Modal
        dataTable={dataTable}
        Form={ModalFacturacion}
        modal={modal}
        closeModal={closeModal}
        tipo={type}
        color={color}
        nombre={"Factura"}
      />
    </>
  );
}
