import React from "react";

// @material-ui/icons
import LocalGroceryStoreIcon from "@material-ui/icons/LocalGroceryStoreOutlined";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ItemSearch from "components/CompraVenta/ItemSearch.js";
import ItemsConfirm from "components/CompraVenta/ItemsConfirm.js";

// redux
import { useSelector } from "react-redux";
import { updateItems, comprar, addItem } from "../redux/compraDucks.js";

export default function Compras() {
  const items = useSelector((store) => store.compras.items);
  const header = [
    {
      Header: "Nombre",
      accessor: "nombre",
    },
    {
      Header: "Precio Costo",
      accessor: "precioCosto",
    },
    {
      Header: "Precio Venta",
      accessor: "precioVenta",
    },
    {
      Header: "Existencia",
      accessor: "existencia",
    },
    {
      Header: "Fecha Vencimiento",
      accessor: "fechaVencimiento",
    },
    {
      Header: "Descripcion",
      accessor: "descripcion",
    },
  ];
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} md={7}>
          <ItemSearch
            Icon={LocalGroceryStoreIcon}
            type={1}
            addItem={addItem}
            updateItems={updateItems}
            color={"Primary"}
            header={header}
          />
        </GridItem>
        <GridItem xs={12} md={5}>
          <ItemsConfirm
            Icon={LocalGroceryStoreIcon}
            type={1}
            updateItems={updateItems}
            compraVenta={comprar}
            items={items}
            color={"Primary"}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}
