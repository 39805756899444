/*eslint-disable*/
import React from "react";
// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// redux
import { useSelector, useDispatch } from "react-redux";
import { deleteAlert } from "../../redux/alertConfirm";

// style
import styles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

const useStyles = makeStyles(styles);

export default function DeleteAlert() {
    const dispatch = useDispatch();
    const alerta = useSelector((store) => store.alertasConfirm);
    const classes = useStyles();
    const [alert, setAlert] = React.useState(null);

    const warningWithConfirmMessage = () => {
        setAlert(
            <SweetAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="Are you sure?"
                onConfirm={() => successDelete()}
                onCancel={() => hideAlert()}
                confirmBtnCssClass={classes.button + " " + classes.success}
                cancelBtnCssClass={classes.button + " " + classes.danger}
                confirmBtnText="Yes, delete it!"
                cancelBtnText="Cancel"
                showCancelstyle={{ zIndex: "22" }}
            >
                You will not be able to recover this imaginary file!
            </SweetAlert>
        );
    };
    const warningWithConfirmAndCancelMessage = () => {
        setAlert(
            <SweetAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="Are you sure?"
                onConfirm={() => successDelete()}
                onCancel={() => cancelDetele()}
                confirmBtnCssClass={classes.button + " " + classes.success}
                cancelBtnCssClass={classes.button + " " + classes.danger}
                confirmBtnText="Yes, delete it!"
                cancelBtnText="Cancel"
                showCancel
            >
                You will not be able to recover this imaginary file!
            </SweetAlert>
        );
    };
    const successDelete = () => {
        setAlert(
            <SweetAlert
                info
                style={{ display: "block", marginTop: "-100px" }}
                title="Procesando acción"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnCssClass={classes.button + " " + classes.success}
            />
        );
    };
    const cancelDetele = () => {
        setAlert(
            <SweetAlert
                danger
                style={{ display: "block", marginTop: "-100px" }}
                title="Accion cancelada"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnCssClass={classes.button + " " + classes.success}
            />
        );
    };

    const hideAlert = () => {
        setAlert(null);
    };

    const closeAlert = (type) => {
        dispatch(deleteAlert())
        if (type !== 0) return cancelDetele();
        successDelete();
        alerta.func();
    }

    return (
        <div>
            {alerta ? <SweetAlert
                warning
                style={{ display: "block", marginTop: "-100px", zIndex: "20000 !important" }}
                title={alerta.title}
                onConfirm={() => closeAlert(0)}
                onCancel={() => closeAlert(1)}
                confirmBtnCssClass={classes.button + " " + classes.success}
                cancelBtnCssClass={classes.button + " " + classes.danger}
                confirmBtnText="Confirmar"
                cancelBtnText="Cancelar"
                showCancel
            >
                {alerta.info}
            </SweetAlert> : null}
            {alert}
        </div>
    );
}