/*eslint-disable*/
export const getDate = (date) => {
  try {
    if (!date) return "";
    const splitDate = date.split("/");
    const newDate = new Date(
      splitDate[1] + "/" + splitDate[0] + "/" + splitDate[2] + "/"
    );
    return newDate.toISOString().split("T")[0];
  } catch (error) {
    return "";
  }
};

const fillHour = (dayMont) => {
  if(dayMont.length === 1){
    return '0'+dayMont
  }
  return dayMont
}

export const getNewDate = () => {
  const date = new Date()
  let dateSplit = date.toLocaleString()
  dateSplit = dateSplit.split(', ')

  let stringDate = dateSplit[0].split('/')
  const auxHour = dateSplit[1].split(':')

  let newFormat = `${stringDate[2]}-${getMonthDayFormat(stringDate[1])}-${getMonthDayFormat(stringDate[0])}`
  const newHour = `${fillHour(auxHour[0])}:${fillHour(auxHour[1])}:${fillHour(auxHour[2])}`

  return newFormat + `T${newHour}.000-06:00`
}

const getMonthDayFormat = (date) => {
  if (date.length === 1) {
      return '0' + date
  }
  return date
}
export const getMoneyFormat = (amount) => {
  return (amount).toLocaleString('es-MX', {
    style: 'currency',
    currency: 'USD',
  }).replace("USD", "Q");
}