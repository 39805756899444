/*eslint-disable*/
import React, { useRef } from "react";

// core components
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Table from "components/Table/Table.js";
import Modal from "components/Modal/Modal.js";
import ModalFacturacion from "components/ModalForm/ModalFacturacionDetalle";
import Button from "components/CustomButtons/Button.js";

// redux
import { useDispatch } from "react-redux";

// styles
import styles1 from "assets/jss/material-dashboard-pro-react/modalStyle.js";
import styles2 from "assets/jss/material-dashboard-pro-react/components/customInputStyle.js";
import styles3 from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

const useStyles = makeStyles({
  ...styles1, ...styles2, ...styles3
});

export default function ListadoCV({
  filtrar,
  header,
  data,
  Icon,
  type,
  color = "Info",
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [activateModal, setActivateModal] = React.useState(false);
  const [dataTable, setDataTable] = React.useState([]);
  const [input, setInput] = React.useState("");


  const getMoneyFormat = (amount) => {
    return (amount).toLocaleString('es-MX', {
      style: 'currency',
      currency: 'USD',
    }).replace("USD", "Q");
  }

  const rowSelect = (data) => {
    setActivateModal(true);
    const newDataTable = data.productos.map((item, key) => {
      return [
        key + 1,
        item.nombre,
        item.cantidad,
        type === 2 ? getMoneyFormat(item.precioVenta) : getMoneyFormat(item.precioCosto),
        getMoneyFormat(item.total),
      ]
    });
    setDataTable({
      id: data._id,
      productos: data.productos,
      nit: data.nit,
      fecha: data.fecha,
      uuid: data.uuid,
      uuidEmision: data.uuidEmision,
      nombre: data.nombre,
      anulado: data.anulado ? true : false,
      data: [...newDataTable,
      { total: true, colspan: "3", amount: getMoneyFormat(data.total) }
      ]
    }
    );
  }

  const search = () => {
    dispatch(filtrar(input))
  }

  return (
    <>
      <Card style={{ position: "sticky" }}>
        <CardHeader icon>
          <GridContainer justify="space-between">
            <GridItem style={{ marginLeft: `18px` }}>
              <CardIcon
                onClick={() => window.location.reload(false)}
                color={color.toLowerCase()}
              >
                <Icon className={classes.icon} />
              </CardIcon>
            </GridItem>
            <GridItem>
              <CardIcon color="success">
                <Icon className={classes.icon} />
              </CardIcon>
            </GridItem>
          </GridContainer>
        </CardHeader>
        <CardBody>
          <GridContainer justify="center">
            <GridItem xs={3}>
              <TextField
                fullWidth
                className={classes.formControl}
                InputProps={{
                  onChange: (e) => setInput(e.target.value.toLowerCase()),
                  className: classes["input" + color],
                  value: input,
                }}
                variant="standard"
              />
            </GridItem>
          </GridContainer>
          <GridContainer justify="center">
            <Button color={"success"} onClick={() => search()}> Buscar </Button>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12}>
              <Table
                hover
                tableHead={header}
                tableData={data}
                rowSelect={rowSelect}
              />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      <Modal
        style={{ position: "sticky" }}
        dataTable={dataTable}
        Form={ModalFacturacion}
        modal={activateModal}
        closeModal={setActivateModal}
        tipo={type}
        color={color}
        nombre={"Factura"}
      />
    </>
  );
}
