/*eslint-disable*/
import React from "react";

// @material-ui/icons
import ReceiptTwoTone from "@material-ui/icons/ReceiptTwoTone";

// core components
import ListaCV from "./ListadoCV.js";

// redux
import { useDispatch, useSelector } from "react-redux";
import { obtenerVentas } from "../redux/ventaDucks";

export default function Ventas() {
  const dispatch = useDispatch();
  const ventas = useSelector((store) => store.ventas.ventas);
  const header = ["NIT", "Nombre", "Cantidad", "Fecha Venta" ];
  
  React.useEffect(() => {
    dispatch(obtenerVentas());
  }, [dispatch]);

  return (
    <ListaCV 
      filtrar={obtenerVentas}
      Icon={ReceiptTwoTone}
      color={"Warning"}
      header={header}
      data={ventas}
      type={2}
    />
  );
}
