/*eslint-disable*/
const SET_ALERT_CONFIRM = "SET_ALERT_CONFIRM";
const DELETE_ALERT_CONFIRM = "DELETE_ALERT_CONFIRM";

export default function alertasConfirmReducer(state = null, action) {
  switch (action.type) {
    case SET_ALERT_CONFIRM:
      return { ...action.payload };
    case DELETE_ALERT_CONFIRM:
      return null;
    default:
      return state;
  }
}

export const setAlert = (data) => (dispatch) => {
  dispatch({
    type: SET_ALERT_CONFIRM,
    payload: data,
  });
};

export const deleteAlert = () => (dispatch) => {
  dispatch({
    type: DELETE_ALERT_CONFIRM,
  });
};