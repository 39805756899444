/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

// style for this view
import styles1 from "assets/jss/material-dashboard-pro-react/modalStyle.js";
import styles2 from "assets/jss/material-dashboard-pro-react/components/customInputStyle.js";

// validate inputs
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from 'formik';
import { ComprasSchema } from "./schemas";

const useStyles = makeStyles({
    ...styles1, ...styles2,
});

export default function ComprasForm({
    color = "Info",
    addProduct,
    activateAdd,
    selected,
    type,
    focusTextfielt
}) {
    const classes = useStyles();
    const usuario = useSelector((store) => store.session);
    const initialData = {
        cantidad: "1",
        precioCosto: selected.precioCosto + "",
        precioVenta: selected.precioVenta + "",
        fechaVencimiento: selected.fechaVencimiento + ""
    };
    const formik = useFormik({
        initialValues: initialData,
        enableReinitialize: true,
        validationSchema: ComprasSchema,
        onSubmit: (values) => {
            const dataTemp = {
                cantidad: values.cantidad,
                precioCosto: values.precioCosto,
                precioVenta: values.precioVenta,
                fechaVencimiento: values.fechaVencimiento
            }
            addProduct(dataTemp)
        },
    });

    const getMoneyIcon = (type) => {
        if (type === "precioCosto" || type === "precioVenta") {
            return (
                <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                >
                    Q{""}
                </InputAdornment>
            );
        } else {
            return null;
        }
    };

    const getForm = () => {
        const size = type === 1 ? 2 : 4
        const disable = type === 1 ? true : false
        return (
            <>
                <GridContainer>
                    <GridItem xs={12} sm={size}>
                        {inpuForm2("Cantidad", "number", "cantidad")}
                    </GridItem>
                    {
                        disable ?
                            (
                                <GridItem xs={12} sm={size}>
                                    {inpuForm("Precio Costo", "number", "precioCosto")}
                                </GridItem>

                            ) : null
                    }
                    <GridItem xs={12} sm={size}>
                        {inpuForm("Precio Venta", "number", "precioVenta")}
                    </GridItem>

                    {
                        disable ?
                            (
                                <GridItem xs={12} sm={size + 1}>
                                    {inpuForm("Fecha Vencimiento", "date", "fechaVencimiento")}
                                </GridItem>
                            ) : null
                    }
                    <GridItem xs={12} sm={size === 2 ? 3 : 4}>
                        <Button disabled={!activateAdd} style={{ marginTop: "30px", width: "100%" }} color={activateAdd ? color.toLowerCase() : null} type="submit">
                            {"Agregar"}
                        </Button>
                    </GridItem>
                </GridContainer >
            </>
        )
    }
    const inpuForm = (name, type, id) => {
        const disable = name === "Precio Venta" && !((usuario && usuario.tipo === "administrador") || selected.permisoMod)
        return (<TextField
            disabled={disable}
            onFocus={(event) => event.target.select()}
            autoFocus={formik.values[id] === "1"}
            fullWidth
            className={classes["formControl" + color]}
            InputProps={{
                className: classes["input" + color],
                onChange: formik.handleChange,
                id: id,
                name: id,
                value: formik.values[id] !== null ? formik.values[id] : "",
                startAdornment: getMoneyIcon(id),
            }}
            label={name}
            error={formik.touched[id] && Boolean(formik.errors[id])}
            helperText={formik.touched[id] && formik.errors[id]}
            type={type}
        />
        )
    }
    const inpuForm2 = (name, type, id) => {
        const disable = name === "Precio Venta" && !((usuario && usuario.tipo === "administrador") || selected.permisoMod)
        return (<TextField
            disabled={disable}
            onFocus={(event) => event.target.select()}
            autoFocus={formik.values[id] === "1"}
            fullWidth
            className={classes["formControl" + color]}
            inputRef={focusTextfielt}
            InputProps={{
                className: classes["input" + color],
                onChange: formik.handleChange,
                id: id,
                name: id,
                value: formik.values[id] !== null ? formik.values[id] : "",
                startAdornment: getMoneyIcon(id),
            }}
            label={name}
            error={formik.touched[id] && Boolean(formik.errors[id])}
            helperText={formik.touched[id] && formik.errors[id]}
            type={type}
        />
        )
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            {getForm()}
        </form>
    );
}
