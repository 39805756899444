import * as yup from "yup";

const ComprasSchema = yup.object({
  precioCosto: yup
    .number("Ingresar precio costo")
    .min(0, "Debe de ser positivo")
    .required("El precio costo es requerido"),
  precioVenta: yup
    .number("Ingresar precio venta")
    .min(0, "Debe de ser positivo")
    .required("El precio venta es requerido"),
  cantidad: yup
    .number("Ingresar cantidad")
    .min(1, "La cantidad debe de ser mayor a 0")
    .required("La cantidad es requerida"),
});

const ComprasArraySchema = yup.array().of(
  yup.object().shape({
    precioCosto: yup
      .number("Ingresar precio costo")
      .min(0, "Debe de ser positivo")
      .required("El precio costo es requerido"),
    precioVenta: yup
      .number("Ingresar precio venta")
      .min(0, "Debe de ser positivo")
      .required("El precio venta es requerido"),
    cantidad: yup
      .number("Ingresar cantidad")
      .min(1, "Debe de ser mayor a 0")
      .required("La cantidad es requerida"),
  })
);

export { ComprasSchema, ComprasArraySchema };
