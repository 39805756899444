/*eslint-disable*/
import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import productoReducer from "./productoDucks";
import proveedorReducer from "./proveedorDucks";
import comprasReducer from "./compraDucks";
import ventasReducer from "./ventaDucks";
import usuariosReducer from "./usuarioDucks";
import alertasReducer from "./alertDuck";
import alertasConfirmReducer from "./alertConfirm";
import reportesReducer from "./reportesDucks";
import sessionReducer from "./sessionDucks";
import axios from "axios";

// axios configuration
//axios.defaults.baseURL = "http://3.21.219.231:3000";
//axios.defaults.baseURL = "http://localhost:3001";
axios.defaults.baseURL = "https://agropecuaria-srv-nvimmc5nxa-uc.a.run.app";
axios.defaults.headers.common["Authorization"] = "";
axios.defaults.headers.common["Content-Type"] = "application/json";

const rootReducer = combineReducers({
  session: sessionReducer,
  productos: productoReducer,
  proveedores: proveedorReducer,
  compras: comprasReducer,
  ventas: ventasReducer,
  usuarios: usuariosReducer,
  alertas: alertasReducer,
  alertasConfirm: alertasConfirmReducer,
  reportes:reportesReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function generateStore() {
  const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk))
  );
  return store;
}
