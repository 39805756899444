import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

// styles
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

// redux
import { useDispatch } from "react-redux";
import { logIn } from "../../redux/sessionDucks";

// formik
import { useFormik } from "formik";
import { loginSchema } from "../../utils/schemas";

const useStyles = makeStyles(styles);

const LoginPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [cardAnimaton, setCardAnimation] = useState("cardHidden");
  const [_cookies, setCookie] = useCookies(["session"]);

  useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 700);
    return function cleanup() {
      window.clearTimeout(id);
    };
  }, []);

  const formik = useFormik({
    initialValues: { usuario: "", pwd: "" },
    enableReinitialize: true,
    validationSchema: loginSchema,
    onSubmit: async (data) => {
      dispatch(logIn(data, setCookie, () => navigate("/admin/venta")));
    },
  });

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form onSubmit={formik.handleSubmit}>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="warning"
              >
                <h4 className={classes.cardTitle}>Agropecuaria Aldana</h4>
                <div className={classes.socialLine}>
                  {[
                    "	fa fa-shower",
                    "fab fa-envira",
                    "fab fa-pagelines",
                    "fab fa-sticker-mule",
                    "fa fa-paw",
                  ].map((prop, key) => {
                    return (
                      <Button
                        color="transparent"
                        justIcon
                        key={key}
                        className={classes.customButtonClass}
                      >
                        <i className={prop} />
                      </Button>
                    );
                  })}
                </div>
              </CardHeader>
              <CardBody>
                <CustomInput
                  labelText="Usuario"
                  id="usuario"
                  warning
                  formControlProps={{
                    fullWidth: true,
                    onChange: formik.handleChange,
                    name: "usuario",
                    value:
                      formik.values.usuario !== null
                        ? formik.values.usuario
                        : "",
                  }}
                  error={
                    formik.touched.usuario && Boolean(formik.errors.usuario)
                  }
                  helperText={formik.touched.usuario && formik.errors.usuario}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Face className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                  }}
                />
                <CustomInput
                  labelText="Contraseña"
                  id="pwd"
                  warning
                  formControlProps={{
                    fullWidth: true,
                    onChange: formik.handleChange,
                    name: "usuario",
                    value: formik.values.pwd !== null ? formik.values.pwd : "",
                  }}
                  error={formik.touched.pwd && Boolean(formik.errors.pwd)}
                  helperText={formik.touched.pwd && formik.errors.pwd}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "off",
                  }}
                />
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button type="submit" color="warning" simple size="lg" block>
                  Iniciar sesion
                </Button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default LoginPage;