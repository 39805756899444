/*eslint-disable*/
import React from "react";

// core components
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";

// redux
import { useSelector, useDispatch } from "react-redux";
import { deleteAlert } from "../../redux/alertDuck";

export default function Alert() {
  const dispatch = useDispatch();
  const alertas = useSelector((store) => store.alertas);

  const closeAlert = (id) => {
    dispatch(deleteAlert(id));
  };
  return (
    <>
      {alertas.map((alerta) => {
        return <Snackbar
          key={alerta.id}
          place="tc"
          color={alerta.tipo}
          message={alerta.msj}
          open={true}
          closeNotification={() => closeAlert(alerta.id)}
          icon={AddAlert}
          close
        />
      })}

    </>
  );
}